export const DOCS_URL = 'https://docs.thx.network';
export const BASE_URL = process.env.VUE_APP_DASHBOARD_URL || '';
export const AUTH_URL = process.env.VUE_APP_AUTH_URL || '';
export const API_URL = process.env.VUE_APP_API_ROOT || '';
export const PUBLIC_URL = process.env.VUE_APP_PUBLIC_URL || '';
export const WALLET_URL = process.env.VUE_APP_WALLET_URL || '';
export const WIDGET_URL = process.env.VUE_APP_WIDGET_URL || '';
export const OIDC_CLIENT_ID = process.env.VUE_APP_DASHBOARD_OIDC_CLIENT_ID || '';
export const OIDC_CLIENT_SECRET = process.env.VUE_APP_DASHBOARD_OIDC_CLIENT_SECRET || '';
export const GTM = process.env.VUE_APP_GTM || '';
export const WIDGET_ID = process.env.VUE_APP_WIDGET_ID || '';
export const PKG_ENV = process.env.VUE_APP_PKG_ENV as 'local' | 'dev' | 'prod';
export const MIXPANEL_TOKEN = process.env.VUE_APP_MIXPANEL_TOKEN || '';
export const NODE_ENV = process.env.NODE_ENV;
export const DISCORD_CLIENT_ID = process.env.VUE_APP_DISCORD_CLIENT_ID;
export const SUPABASE_URL = process.env.VUE_APP_SUPABASE_URL || '';
export const SUPABASE_PUBLIC_KEY = process.env.VUE_APP_SUPABASE_PUBLIC_KEY || '';
