
import { IPools, TQuestState, TTwitterQueryState } from '@thxnetwork/dashboard/store/modules/pools';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { AccessTokenKind, OAuthRequiredScopes } from '@thxnetwork/common/enums';
import { format } from 'date-fns';
import BaseModalTwitterQueryCreate from '@thxnetwork/dashboard/components/modals/BaseModalTwitterQueryCreate.vue';

@Component({
    components: {
        BaseModalTwitterQueryCreate,
    },
    computed: {
        ...mapGetters({
            account: 'account/profile',
            pools: 'pools/all',
            questList: 'pools/quests',
            twitterQueryList: 'pools/twitterQueries',
        }),
    },
})
export default class IntegrationTwitterView extends Vue {
    account!: TAccount;
    pools!: IPools;
    questList!: TQuestState;
    twitterQueryList!: TTwitterQueryState;
    isLoading = false;
    format = format;

    get pool() {
        return this.pools[this.$route.params.id];
    }

    get quests() {
        if (!this.questList[this.$route.params.id]) return [];
        return this.questList[this.$route.params.id].results;
    }

    get options() {
        return this.quests.map((quest: TQuest) => {
            return { text: quest.title, value: { variant: quest.variant, questId: quest._id } };
        });
    }

    get queries() {
        if (!this.twitterQueryList[this.$route.params.id]) return [];
        return Object.values(this.twitterQueryList[this.$route.params.id]).map((query) => {
            return {
                queryString: query.query,
                frequency: query.frequencyInHours,
                posts: query.posts.length,
                createdAt: query.createdAt,
                query,
            };
        });
    }

    get isConnected() {
        if (!this.pool.owner) return;
        return this.pool.owner.tokens.find(
            ({ kind, scopes }) =>
                kind === AccessTokenKind.Twitter &&
                OAuthRequiredScopes.TwitterAutoQuest.every((scope) => scopes.includes(scope)),
        );
    }

    get isDisabled() {
        return !this.isConnected;
    }

    mounted() {
        this.$store.dispatch('pools/listTwitterQueries', {
            pool: this.pool,
        });
        this.$store.dispatch('pools/listQuests', {
            pool: this.pool,
            page: 1,
            limit: 100,
            isPublished: true,
        });
    }

    onClickDelete(query: TTwitterQuery) {
        this.$store.dispatch('pools/removeTwitterQuery', { query });
    }

    onClickConnect() {
        this.$store.dispatch('auth/connect', {
            kind: AccessTokenKind.Twitter,
            scopes: OAuthRequiredScopes.TwitterAutoQuest,
        });
    }
}
