
import { Component, Prop, Vue } from 'vue-property-decorator';
import Color from 'color';

@Component({})
export default class WidgetsView extends Vue {
    Color = Color;

    @Prop() icon!: string;
    @Prop() text!: string;
    @Prop() color!: string;
    @Prop() lighten!: number;
    @Prop() darken!: number;
}
