
import { IPools, TGuildState } from '@thxnetwork/dashboard/store/modules/pools';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { BASE_URL } from '@thxnetwork/dashboard/config/secrets';
import { DISCORD_BOT_INVITE_URL } from '@thxnetwork/dashboard/config/constants';
import { AccessTokenKind, OAuthDiscordScope } from '@thxnetwork/common/enums';
import BaseCardURLWebhook from '@thxnetwork/dashboard/components/cards/BaseCardURLWebhook.vue';
import BaseDropdownDiscordChannel from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownDiscordChannel.vue';
import BaseDropdownDiscordRole from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownDiscordRole.vue';
import BaseDropdownSelectMultiple from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownSelectMultiple.vue';
import BaseCollapseDiscordNotifications from '@thxnetwork/dashboard/components/BaseCollapseDiscordNotifications.vue';

@Component({
    components: {
        BaseCardURLWebhook,
        BaseDropdownSelectMultiple,
        BaseDropdownDiscordChannel,
        BaseDropdownDiscordRole,
        BaseCollapseDiscordNotifications,
    },
    computed: {
        ...mapGetters({
            pools: 'pools/all',
            guildList: 'pools/guilds',
            account: 'account/profile',
        }),
    },
})
export default class IntegrationDiscordView extends Vue {
    BASE_URL = BASE_URL;
    discordBotInviteUrl = DISCORD_BOT_INVITE_URL;
    isChecked = true;
    isLoading = false;
    account!: TAccount;
    pools!: IPools;
    guildList!: TGuildState;

    get pool() {
        return this.pools[this.$route.params.id];
    }

    get isOwner() {
        if (!this.pool.owner) return;
        return this.pool.owner.sub === this.account.sub;
    }

    get guilds() {
        if (!this.guildList[this.$route.params.id]) return [];
        return Object.values(this.guildList[this.$route.params.id]);
    }

    get discordToken() {
        if (!this.pool.owner) return;
        return this.pool.owner.tokens.find(
            ({ kind, scopes }) => kind === AccessTokenKind.Discord && scopes.includes(OAuthDiscordScope.Guilds),
        );
    }

    get options() {
        if (!this.guildList[this.$route.params.id]) return [];
        return Object.values(this.guildList[this.$route.params.id])
            .filter((guild: TDiscordGuild) => (guild.permissions & 0x00000008) === 0x00000008)
            .map((guild: TDiscordGuild) => ({
                img: guild.icon && `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`,
                label: guild.name,
                value: guild,
                disabled: guild.isConnected,
                selected: guild.isConnected,
                icon: guild.isInvited && {
                    variant: guild.isConnected ? 'success' : 'danger',
                    class: guild.isConnected ? 'fas fa-check' : 'fas fa-exclamation',
                },
            }));
    }

    get connectedGuilds() {
        return this.guilds.filter((guild: TDiscordGuild) => guild.isConnected);
    }

    async mounted() {
        if (this.discordToken) {
            this.isLoading = true;
            await this.$store.dispatch('pools/listGuilds', this.pool);
            this.isLoading = false;
        }
    }

    onClickDiscordRole(guild: TDiscordGuild, role: TDiscordRole) {
        this.updateDiscordGuild({ ...guild, adminRoleId: role.id });
    }

    onChangeGuildSecret(guild: TDiscordGuild, secret: string) {
        this.updateDiscordGuild({ ...guild, secret });
    }

    updateDiscordGuild(guild: TDiscordGuild) {
        this.$store.dispatch('pools/updateGuild', guild);
    }

    onSelectGuild(guild: TDiscordGuild) {
        this.$store.dispatch('pools/createGuild', guild);
    }

    onRemoveGuild(guild: TDiscordGuild) {
        this.$store.dispatch('pools/removeGuild', guild);
    }

    onClickConnect() {
        this.$store.dispatch('auth/connect', {
            kind: AccessTokenKind.Discord,
            scopes: [OAuthDiscordScope.Identify, OAuthDiscordScope.Email, OAuthDiscordScope.Guilds],
        });
    }
}
