
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseBtnToggleArchive extends Vue {
    showArchived = false;

    toggleShowArchived() {
        this.showArchived = !this.showArchived;
        this.$emit('archived', this.showArchived);
    }
}
