
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TCouponCodeState } from '@thxnetwork/dashboard/store/modules/pools';
import BaseParticipantAccount from '../BaseParticipantAccount.vue';

@Component({
    components: {
        BaseParticipantAccount,
    },
    computed: mapGetters({
        couponCodeList: 'pools/couponCodes',
    }),
})
export default class ModalRewardCustomCreate extends Vue {
    isLoading = false;
    error = '';
    codes: string[] = [];
    page = 1;
    limit = 10;
    query = '';

    couponCodeList!: TCouponCodeState;

    @Prop() pool!: TPool;
    @Prop({ required: true }) reward!: TRewardCoupon;

    get total() {
        if (!this.reward || !this.couponCodeList[this.pool._id] || !this.couponCodeList[this.pool._id][this.reward._id])
            return 0;
        return this.couponCodeList[this.pool._id][this.reward._id].total;
    }

    get couponCodes() {
        if (!this.couponCodeList[this.pool._id] || !this.couponCodeList[this.pool._id][this.reward._id]) return [];
        return this.couponCodeList[this.pool._id][this.reward._id].results.map((c) => ({
            code: c.code,
            account: c.account,
            id: c._id,
        }));
    }

    async mounted() {
        this.codes = [];
        if (this.reward) {
            await this.listCouponCodes();
        }
    }

    async onInputSearch() {
        this.page = 1;
        await this.listCouponCodes();
    }

    async listCouponCodes() {
        await this.$store.dispatch(`pools/listCouponCodes`, {
            pool: this.pool,
            reward: this.reward,
            page: this.page,
            limit: this.limit,
            query: this.query,
        });
    }

    onChangePage() {
        this.listCouponCodes();
    }

    onClickDeleteCode(item: any) {
        this.$store.dispatch(`pools/deleteCouponCode`, {
            reward: this.reward,
            couponCodeId: item.id,
        });
    }
}
