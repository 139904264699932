
import { format } from 'date-fns';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import type { IERC721Metadatas, IERC721s, TNFTMetadata } from '@thxnetwork/dashboard/types/erc721';
import type { IERC1155Metadatas, IERC1155s } from '@thxnetwork/dashboard/types/erc1155';
import { NFTVariant } from '@thxnetwork/common/enums';
import { API_URL } from '@thxnetwork/dashboard/config/secrets';
import BaseCardErc721Metadata from '@thxnetwork/dashboard/components/cards/BaseCardERC721Metadata.vue';
import BaseModalErc721MetadataCreate from '@thxnetwork/dashboard/components/modals/BaseModalERC721MetadataCreate.vue';
import BaseModalErc721MetadataBulkCreate from '@thxnetwork/dashboard/components/modals/BaseModalERC721MetadataBulkCreate.vue';
import BaseModalErc721MetadataUploadCSV from '@thxnetwork/dashboard/components/modals/BaseModalERC721MetadataUploadCSV.vue';
import BaseModalErc721MetadataCreateCSV from '@thxnetwork/dashboard/components/modals/BaseModalERC721MetadataCreateCSV.vue';
import BaseCardTableHeader from '@thxnetwork/dashboard/components/cards/BaseCardTableHeader.vue';

@Component({
    components: {
        BaseCardErc721Metadata,
        BaseModalErc721MetadataCreate,
        BaseModalErc721MetadataBulkCreate,
        BaseModalErc721MetadataUploadCSV,
        BaseModalErc721MetadataCreateCSV,
        BaseCardTableHeader,
    },
    computed: mapGetters({
        totals: 'erc721/totalsMetadata',
        erc721s: 'erc721/all',
        erc721Metadata: 'erc721/metadata',
        erc1155s: 'erc1155/all',
        erc1155Metadata: 'erc1155/metadata',
    }),
})
export default class MetadataView extends Vue {
    page = 1;
    limit = 5;
    isLoading = true;
    format = format;
    totals!: { [erc721Id: string]: number };
    docsUrl = process.env.VUE_APP_DOCS_URL;
    apiUrl = API_URL;
    widgetUrl = process.env.VUE_APP_WIDGET_URL;
    qrURL = '';
    selectedItems: any[] = [];
    erc721s!: IERC721s;
    erc1155s!: IERC1155s;
    erc721Metadata!: IERC721Metadatas;
    erc1155Metadata!: IERC1155Metadatas;

    get nft() {
        switch (this.$route.params.variant) {
            case NFTVariant.ERC721:
                if (!this.erc721s[this.$route.params.nftId]) return;
                return this.erc721s[this.$route.params.nftId];
            case NFTVariant.ERC1155:
                if (!this.erc1155s[this.$route.params.nftId]) return;
                return this.erc1155s[this.$route.params.nftId];
            default:
                return;
        }
    }

    get metadata() {
        switch (this.$route.params.variant) {
            case NFTVariant.ERC721:
                if (!this.erc721Metadata[this.$route.params.nftId]) return;
                return this.erc721Metadata[this.$route.params.nftId];
            case NFTVariant.ERC1155:
                if (!this.erc1155Metadata[this.$route.params.nftId]) return;
                return this.erc1155Metadata[this.$route.params.nftId];
            default:
                return;
        }
    }

    get metadataByPage() {
        if (!this.nft || !this.metadata) return [];
        return Object.values(this.metadata)
            .filter((metadata: TNFTMetadata) => metadata.page === this.page)
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((r: TNFTMetadata) => ({
                checkbox: r._id,
                image: r.imageUrl,
                info: { name: r.name, description: r.description, url: r.externalUrl },
                created: r.createdAt,
                id: r._id,
            }))
            .slice(0, this.limit);
    }

    async mounted() {
        this.listMetadata();
    }

    getMetadataURL(metadata: TNFTMetadata) {
        switch (this.$route.params.variant) {
            case NFTVariant.ERC721:
                return `${this.apiUrl}/metadata/${metadata._id}`;
            case NFTVariant.ERC1155:
                return `${this.apiUrl}/metadata/erc1155/${this.nft?._id}/${metadata.tokenId}`;
        }
    }
    onChangePage(page: number) {
        this.page = page;
        this.listMetadata();
    }

    async onClickDelete(metadata: TNFTMetadata) {
        if (metadata.tokens.length) throw new Error('This metadata is being used.');
        await this.$store.dispatch(`${this.$route.params.variant}/deleteMetadata`, {
            erc721: this.nft,
            erc1155: this.nft,
            metadata,
        });
    }

    onSelectAll(isSelectAll: boolean) {
        this.selectedItems = isSelectAll ? (this.metadataByPage.map((r) => r.id) as string[]) : [];
    }

    onChangeLimit(limit: number) {
        this.limit = limit;
        this.listMetadata();
    }

    onClickAction(action: { variant: number; label: string }) {
        if (!this.nft) return;
        switch (action.variant) {
            case 0:
                for (const id of Object.values(this.selectedItems)) {
                    this.$store.dispatch(`${this.$route.params.variant}/deleteMetadata`, {
                        erc721: this.nft,
                        erc1155: this.nft,
                        metadata: this.metadata && this.metadata[this.nft._id][id],
                    });
                }
                break;
            case 1:
                this.$bvModal.show('modalRewardERC721Create');
                break;
        }
    }

    async listMetadata() {
        this.isLoading = true;
        await this.$store.dispatch(this.$route.params.variant + '/read', this.$route.params.nftId);
        await this.$store.dispatch(this.$route.params.variant + '/listMetadata', {
            erc721: this.nft,
            erc1155: this.nft,
            page: this.page,
            limit: this.limit,
        });
        this.isLoading = false;
    }
}
