
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

@Component({
    computed: {
        ...mapState('auth', {
            session: 'session',
        }),
        ...mapGetters({
            account: 'account/profile',
        }),
    },
})
export default class ViewAuthRedirect extends Vue {
    pools!: IPools;
    account!: TAccount;
    error = '';

    get isAlertDangerShown() {
        return this.error !== '';
    }
    get isAlertSuccessShown() {
        return !this.error;
    }

    async created() {
        // Check query params for error
        if (this.$route.query.error) {
            this.error = this.$route.query.error_description as string;
        }
    }

    onClickClose() {
        window.close();
    }
}
