
import { Component, Vue } from 'vue-property-decorator';
import { NFTVariant } from '@thxnetwork/common/enums';

@Component({})
export default class ModalAssetPoolCreate extends Vue {
    NFTVariant = NFTVariant;
    variant: NFTVariant | null = null;

    onListItemClick(variant: NFTVariant | null) {
        this.variant = variant;
        this.$emit('selected', this.variant);
    }
}
