
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { validateEmail } from '@thxnetwork/dashboard/utils/email';
import BaseListItemCollaborator from '@thxnetwork/dashboard/components/list-items/BaseListItemCollaborator.vue';

@Component({
    components: {
        BaseListItemCollaborator,
    },
    computed: {
        ...mapGetters({
            brands: 'brands/all',
            pools: 'pools/all',
            profile: 'account/profile',
        }),
    },
})
export default class SettingsView extends Vue {
    loading = true;
    error = '';
    profile!: TAccount;
    pools!: IPools;
    errorCollaborator = '';
    emailCollaborator = '';
    isSubmittingCollaborator = false;

    get pool() {
        return this.pools[this.$route.params.id];
    }

    get isValidCollaboratorEmail() {
        if (!this.emailCollaborator) return null;
        return !!validateEmail(this.emailCollaborator);
    }

    async sendInvite(email: string) {
        this.isSubmittingCollaborator = true;
        try {
            await this.$store.dispatch('pools/inviteCollaborator', { pool: this.pool, email });
        } catch (error) {
            this.errorCollaborator = (error as any).response.data.error.message;
        } finally {
            this.isSubmittingCollaborator = false;
        }
    }

    onClickCollaboratorInvite() {
        if (!this.isValidCollaboratorEmail) return;
        this.sendInvite(this.emailCollaborator);
    }
}
