
import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { fromWei } from 'web3-utils';
import { format } from 'date-fns';
import BaseIdenticon from '@thxnetwork/dashboard/components/BaseIdenticon.vue';
import BaseChartQuests from '@thxnetwork/dashboard/components/charts/BaseChartQuests.vue';
import BaseChartRewards from '@thxnetwork/dashboard/components/charts/BaseChartRewards.vue';
import BaseDateDuration from '@thxnetwork/dashboard/components/form-group/BaseDateDuration.vue';
import BaseCardLeaderboard from '@thxnetwork/dashboard/components/cards/BaseCardLeaderboard.vue';

const ONE_DAY = 86400000; // one day in ms
const startDate = new Date(Date.now() - ONE_DAY * 7);
const endDate = new Date();
endDate.setHours(23, 59, 59, 999);

@Component({
    components: {
        BaseIdenticon,
        BaseChartQuests,
        BaseChartRewards,
        BaseDateDuration,
        BaseCardLeaderboard,
    },
    computed: mapGetters({
        analyticsMetrics: 'pools/analyticsMetrics',
    }),
})
export default class ViewAnalyticsMetrics extends Vue {
    fromWei = fromWei;
    format = format;
    metricQuestsLabelMap = ['Daily', 'Invite', 'Social', 'Custom', 'Web3', 'Gitcoin'];
    metricQuestsInfoMap = [
        'Daily Quest qualifications versus completed and the total amount of points earned.',
        'Invite Quest leads qualified and the total amount of points earned.',
        'Social Quests completed and the total amount of points earned.',
        'Custom Quest qualifications versus completions and the total amount of points earned.',
        'Web3 Quests completed and the total amount of points earned.',
        'Gitcoin Quests completed and the total amount of points earned.',
    ];
    metricRewardLabelMap = ['Coin', 'NFT', 'Custom', 'Coupon', 'Discord Role'];
    isLoadingCharts = false;
    isLoadingMetrics = false;
    analyticsMetrics!: IPoolAnalyticsMetrics;
    defaultDates = {
        startDate,
        endDate,
    };
    startDate = startDate;
    endDate = endDate;

    @Prop() pool!: TPool;

    get metrics() {
        if (!this.analyticsMetrics[this.$route.params.id]) return null;
        return this.analyticsMetrics[this.$route.params.id];
    }

    get isLoading() {
        return this.isLoadingMetrics || this.isLoadingCharts;
    }

    get chartDates() {
        const oneDay = 86400000; // one day in milliseconds
        const dates: string[] = [];

        for (let i = 0; i <= this.endDate.getTime() - this.startDate.getTime(); ) {
            dates.push(format(new Date(this.startDate.getTime() + i), 'yyyy-MM-dd'));
            i += oneDay;
        }

        return dates;
    }

    async mounted() {
        this.getCharts();
        this.getMetrics();
    }

    formatDateLabel(date: Date): string {
        const month = date.getUTCMonth() + 1;
        const day = date.getDate();
        return `${month}/${day}`;
    }

    async getMetrics() {
        this.isLoadingMetrics = true;
        await this.$store.dispatch('pools/readAnalyticsMetrics', {
            poolId: this.pool._id,
            startDate: this.startDate,
            endDate: this.endDate,
        });
        this.isLoadingMetrics = false;
    }

    async getCharts() {
        this.isLoadingCharts = true;
        await this.$store.dispatch('pools/readAnalytics', {
            poolId: this.pool._id,
            startDate: this.startDate,
            endDate: this.endDate,
        });
        this.isLoadingCharts = false;
    }

    onInputStartDate(startDate: Date) {
        this.startDate = startDate;
        this.update();
    }

    onInputEndDate(endDate: Date) {
        this.endDate = endDate;
        this.endDate.setHours(23, 59, 59, 999);
        this.update();
    }

    update() {
        this.getCharts();
        this.getMetrics();
    }
}
