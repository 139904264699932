
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { ChainId } from '@thxnetwork/common/enums';
import BaseIdenticon from '../BaseIdenticon.vue';
import type { IERC721s, TERC721 } from '@thxnetwork/dashboard/types/erc721';
import type { IERC1155s, TERC1155 } from '@thxnetwork/dashboard/types/erc1155';

@Component({
    components: {
        BaseIdenticon,
    },
    computed: mapGetters({
        erc721s: 'erc721/all',
        erc1155s: 'erc1155/all',
    }),
})
export default class BaseDropdownSelectNFT extends Vue {
    ChainId = ChainId;
    tokenList: TERC721[] = [];

    erc721s!: IERC721s;
    erc1155s!: IERC1155s;

    @Prop({ required: false }) nft!: TERC1155 | TERC721;
    @Prop() chainId!: ChainId;

    get hasNFTs() {
        return !!Object.values(this.erc721s).length || !!Object.values(this.erc1155s).length;
    }

    async mounted() {
        this.$store.dispatch('erc721/list').then(() => {
            for (const id in this.erc721s) {
                this.$store.dispatch('erc721/read', id);
            }
        });
        this.$store.dispatch('erc1155/list').then(() => {
            for (const id in this.erc1155s) {
                this.$store.dispatch('erc1155/read', id);
            }
        });
    }
}
