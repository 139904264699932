
import { track } from '@thxnetwork/common/mixpanel';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: mapGetters({
        profile: 'account/profile',
    }),
})
export default class BaseModal extends Vue {
    interval: any = null;
    info = '';
    profile!: TAccount;

    @Prop() id!: string;
    @Prop() size!: string;
    @Prop() title!: string;
    @Prop() error!: string;
    @Prop() hideFooter!: boolean;

    onShow() {
        track('UserOpens', [this.profile.sub, this.id || 'unknown']);
        this.$emit('show');
    }
}
