
import { Component, Vue } from 'vue-property-decorator';
import BaseCardPool from '@thxnetwork/dashboard/components/cards/BaseCardPool.vue';
import { mapGetters } from 'vuex';

@Component({
    components: {
        BaseCardPool,
        BaseModalCreateCampaign: () => import('@thxnetwork/dashboard/components/modals/BaseModalPoolCreate.vue'),
    },
    computed: mapGetters({
        pools: 'pools/all',
    }),
})
export default class Tokens extends Vue {
    pools!: IPools;

    mounted() {
        this.$store.dispatch('pools/list');
    }
}
