
import { IPools } from '@thxnetwork/dashboard/store/modules/pools';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import BaseListItemCollaborator from '@thxnetwork/dashboard/components/list-items/BaseListItemCollaborator.vue';
import BaseDateDuration from '@thxnetwork/dashboard/components/form-group/BaseDateDuration.vue';
import BaseModalDelete from '@thxnetwork/dashboard/components/modals/BaseModalDelete.vue';
import slugify from '@thxnetwork/dashboard/utils/slugify';
import { WIDGET_URL } from '@thxnetwork/dashboard/config/secrets';

@Component({
    components: {
        BaseListItemCollaborator,
        BaseDateDuration,
        BaseModalDelete,
    },
    computed: {
        ...mapGetters({
            pools: 'pools/all',
            profile: 'account/profile',
        }),
    },
})
export default class SettingsView extends Vue {
    isCopied = false;
    error = '';
    chainInfo = chainInfo;
    profile!: TAccount;
    pools!: IPools;
    title = '';
    description = '';
    leaderboardInWeeks = 4;
    isWeeklyDigestEnabled = false;
    isPublished = false;
    startDate: Date | null = null;
    endDate: Date | null = null;
    slugify = slugify;
    slug = '';
    isValidSlug: boolean | null = null;
    widgetUrl = WIDGET_URL;

    get pool() {
        return this.pools[this.$route.params.id];
    }

    async mounted() {
        this.title = this.pool.settings.title || this.title;
        this.slug = this.pool.settings.slug || this.slug;
        this.description = this.pool.settings.description;
        this.isPublished = this.pool.settings.isPublished;
        this.isWeeklyDigestEnabled = this.pool.settings.isWeeklyDigestEnabled;
        this.leaderboardInWeeks = this.pool.settings.leaderboardInWeeks;
    }

    async onChangeSlug(slug: string) {
        try {
            if (!slug.length) {
                this.slug = slug = this.pool._id;
            }
            if (slug.length < 3) throw new Error('Slug too short');

            await this.$store.dispatch('pools/update', {
                pool: this.pool,
                data: { settings: { slug: slugify(slug) } },
            });
            this.isValidSlug = null;
        } catch (error) {
            this.isValidSlug = false;
        }
    }

    async onChangeSettings(setting?: Partial<TPoolSettings>) {
        const settings = Object.assign(
            {
                title: this.title,
                slug: this.slug,
                description: this.description,
                leaderboardInWeeks: Number(this.leaderboardInWeeks),
                isPublished: this.isPublished,
                isWeeklyDigestEnabled: this.isWeeklyDigestEnabled,
            },
            setting,
        );

        await this.$store.dispatch('pools/update', {
            pool: this.pool,
            data: { settings },
        });

        this.error = '';
    }

    async remove(_id: string) {
        this.$store.dispatch('pools/remove', { _id });
        this.$router.push({ name: 'home' });
    }
}
