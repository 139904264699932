
import { Component, Prop, Vue } from 'vue-property-decorator';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { ChainId } from '@thxnetwork/common/enums';
import BaseModal from '@thxnetwork/dashboard/components/modals/BaseModal.vue';
import BaseDropdownNetwork from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownNetwork.vue';

@Component({
    components: {
        BaseModal,
        BaseDropdownNetwork,
    },
})
export default class BaseModalWalletCreate extends Vue {
    chainInfo = chainInfo;
    isLoading = false;
    error = '';
    chainId = ChainId.Polygon;

    @Prop() id!: string;
    @Prop() pool!: TPool;

    get isDisabled() {
        return this.isLoading;
    }

    async submit() {
        try {
            this.isLoading = true;

            await this.$store.dispatch('pools/createWallet', {
                pool: this.pool,
                chainId: this.chainId,
            });
            this.$bvModal.hide(this.id);
        } catch (error) {
            this.error = error as string;
        } finally {
            this.isLoading = false;
        }
    }
}
