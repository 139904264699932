
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseModalDelete extends Vue {
    @Prop() id!: string;
    @Prop() subject!: string;
    @Prop() error!: string;
    @Prop() loading!: boolean;
}
