
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseDropdownDiscordRole extends Vue {
    @Prop() roleId!: string;
    @Prop() guild!: TDiscordGuild;

    get selectedDiscordRole() {
        if (!this.guild) return;
        return this.guild.roles.find((role) => role.id === this.roleId);
    }
}
