
import BaseModal from './BaseModal.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        BaseModal,
    },
})
export default class BaseModalClientCreate extends Vue {
    isLoading = false;
    error = '';
    name = '';

    @Prop() id!: string;
    @Prop() client!: TClient;

    get isDisabled() {
        return this.isLoading;
    }

    onShow() {
        this.name = this.client ? this.client.name : this.name;
    }

    async submit() {
        this.isLoading = true;
        const action = this.client ? 'updateClient' : 'createClient';
        await this.$store.dispatch('developer/' + action, {
            payload: {
                ...this.client,
                name: this.name,
            },
        });
        this.$emit('submit');
        this.$bvModal.hide(this.id);
        this.isLoading = false;
    }
}
