
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { API_URL } from '@thxnetwork/dashboard/config/secrets';
import { QuestVariant } from '@thxnetwork/common/enums';
import BaseDropdownEventType from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownEventType.vue';
import BaseModalQuestCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestCreate.vue';

@Component({
    components: {
        BaseModalQuestCreate,
        BaseDropdownEventType,
    },
    computed: mapGetters({
        totals: 'dailyquests/totals',
    }),
})
export default class ModalquestDailyCreate extends Vue {
    isLoading = false;
    error = '';
    amounts = [5, 10, 20, 40, 80, 160, 360];
    eventName = '';
    isVisible = true;

    @Prop() id!: string;
    @Prop() pool!: TPool;
    @Prop({ required: false }) quest!: TQuestDaily;

    get code() {
        return `curl "${API_URL}/v1/webhook/daily/${this.quest ? this.quest.uuid : '<TOKEN>'}" \\
-X POST \\
-d "code=<CODE>"`;
    }

    get isSubmitDisabled() {
        return false;
    }

    onShow() {
        this.amounts = this.quest ? this.quest.amounts : this.amounts;
        this.eventName = this.quest ? this.quest.eventName : this.eventName;
    }

    async onSubmit(payload: TBaseQuest) {
        this.isLoading = true;
        try {
            await this.$store.dispatch(`pools/${this.quest ? 'updateQuest' : 'createQuest'}`, {
                ...this.quest,
                ...payload,
                variant: QuestVariant.Daily,
                amounts: JSON.stringify(this.amounts),
                eventName: this.eventName,
            });
            this.$bvModal.hide(this.id);
            this.$emit('submit', { isPublished: payload.isPublished });
        } catch (error: any) {
            this.error = error.message;
        } finally {
            this.isLoading = false;
        }
    }
}
