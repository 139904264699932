
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseIdenticon extends Vue {
    width = 40;
    height = 40;

    @Prop() size!: string | number;
    @Prop() variant!: string;
    @Prop() rounded!: boolean;
    @Prop() uri!: string;

    mounted() {
        this.width = Number(this.size || this.width);
        this.height = Number(this.size || this.height);
    }
}
