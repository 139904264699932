
import { Component, Vue } from 'vue-property-decorator';

const CARET_UP = 'fa-caret-up';
const CARET_DOWN = 'fa-caret-down';

@Component({})
export default class BaseBtnToggleArchive extends Vue {
    direction = 'desc';
    iconClass = CARET_UP;

    onClick() {
        if (this.direction === 'asc') {
            this.direction = 'desc';
            this.iconClass = CARET_DOWN;
        } else {
            this.direction = 'asc';
            this.iconClass = CARET_UP;
        }

        this.$emit('click', this.direction);
    }
}
