
import BaseNothingHere from '@thxnetwork/dashboard/components/BaseListStateEmpty.vue';
import BaseBtnToggleArchive from '@thxnetwork/dashboard/components/buttons/BaseBtnToggleArchive.vue';
import BaseCardERC1155 from '@thxnetwork/dashboard/components/cards/BaseCardERC1155.vue';
import BaseCardERC721 from '@thxnetwork/dashboard/components/cards/BaseCardERC721.vue';
import ModalErc721Create from '@thxnetwork/dashboard/components/modals/BaseModalERC721Create.vue';
import BaseModalNftImport from '@thxnetwork/dashboard/components/modals/BaseModalNftImport.vue';
import BaseModalPoolCreate from '@thxnetwork/dashboard/components/modals/BaseModalPoolCreate.vue';
import { IERC721s } from '@thxnetwork/dashboard/types/erc721';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { IERC1155s } from '../types/erc1155';

@Component({
    components: {
        BaseBtnToggleArchive,
        BaseCardERC721,
        BaseCardERC1155,
        ModalErc721Create,
        BaseNothingHere,
        BaseModalPoolCreate,
        BaseModalNftImport,
    },
    computed: mapGetters({
        erc721s: 'erc721/all',
        erc1155s: 'erc1155/all',
    }),
})
export default class NFTView extends Vue {
    erc721s!: IERC721s;
    erc1155s!: IERC1155s;

    mounted() {
        this.$store.dispatch('account/get');
        this.$store.dispatch('erc721/list');
        // this.$store.dispatch('erc1155/list');
    }
}
