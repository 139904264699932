
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isValidUrl } from '@thxnetwork/dashboard/utils/url';

@Component({})
export default class BaseCardInfoLinks extends Vue {
    isVisible = false;
    isValidUrl = isValidUrl;

    @Prop() pool!: TPool;
    @Prop({ default: () => [{ key: 0, label: '', url: '' }] }) infoLinks!: TInfoLink[];

    mounted() {
        this.isVisible = !!this.infoLinks.length;
    }

    onChangeLink({ key, label, url }: { key: number; label?: string; url?: string }) {
        const infoLinks = Object.assign(this.infoLinks);
        infoLinks[key] = {
            label: label ? label : this.infoLinks[key].label,
            url: url ? url : this.infoLinks[key].url,
        };
        this.$emit('change', infoLinks);
    }

    onClickRemoveLink(key) {
        const infoLinks = Object.assign(this.infoLinks);
        infoLinks.splice(key, 1);
        this.$emit('change', infoLinks);
    }
    onClickAddLink() {
        const infoLinks = Object.assign(this.infoLinks);
        infoLinks[infoLinks.length] = { label: '', url: '' };
        this.$emit('change', infoLinks);
    }
}
