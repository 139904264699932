
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { AccessTokenKind, OAuthRequiredScopes } from '@thxnetwork/common/enums';
import { validateEmail } from '@thxnetwork/dashboard/utils/email';
import BaseFormGroupConnectedProvider from '@thxnetwork/dashboard/components/form-group/BaseFormGroupConnectedProvider.vue';
import BaseFormGroup from '@thxnetwork/dashboard/components/form-group/BaseFormGroup.vue';

@Component({
    components: {
        BaseFormGroup,
        BaseFormGroupConnectedProvider,
    },
    computed: {
        ...mapGetters({
            account: 'account/profile',
        }),
    },
})
export default class App extends Vue {
    account!: TAccount;
    username = '';
    email = '';
    error = '';
    currentTab = 0;
    isLoading = false;

    mounted() {
        this.username = this.account.username || this.username;
        this.email = this.account.email || this.email;
    }

    get isValidEmail() {
        if (this.email === this.account.email) return null;
        return !!validateEmail(this.email);
    }

    get isValidUsername() {
        if (this.username === this.account.username) return null;
        return this.username.length > 5;
    }

    get providers() {
        return {
            [AccessTokenKind.Google]: {
                kind: AccessTokenKind.Google,
                scopes: OAuthRequiredScopes.GoogleAuth,
                label: 'Google (YouTube)',
                color: '#4285F4',
            },
            [AccessTokenKind.Twitter]: {
                kind: AccessTokenKind.Twitter,
                scopes: OAuthRequiredScopes.TwitterAuth,
                label: 'Twitter',
                color: '#1DA1F2',
            },
            [AccessTokenKind.Discord]: {
                kind: AccessTokenKind.Discord,
                scopes: OAuthRequiredScopes.DiscordAuth,
                label: 'Discord',
                color: '#7289DA',
            },
        };
    }

    async onChange() {
        this.error = '';
        this.isLoading = true;
        try {
            if (this.isValidEmail === false) throw new Error('E-mail is not valid');
            if (this.isValidUsername === false) throw new Error('Username is not valid');

            await this.$store.dispatch('account/update', {
                username: this.username,
                email: this.email,
            });
            this.error = '';
        } catch (error: any) {
            console.error(error);
            if (error.response) {
                this.error = error.response.data.error.message;
            } else {
                this.error = error.message;
            }
        } finally {
            this.isLoading = false;
        }
    }
}
