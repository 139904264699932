
import { ChainId } from '@thxnetwork/common/enums';
import { ERC20Type, TERC20 } from '@thxnetwork/dashboard/types/erc20';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { toWei } from 'web3-utils';
import BaseFormGroupNetwork from '../form-group/BaseFormGroupNetwork.vue';
import BaseModal from './BaseModal.vue';

@Component({
    components: {
        BaseModal,
        BaseFormGroupNetwork,
    },
    computed: mapGetters({}),
})
export default class ModalERC20Create extends Vue {
    ERC20Type = ERC20Type;
    docsUrl = process.env.VUE_APP_DOCS_URL;
    publicUrl = process.env.VUE_APP_PUBLIC_URL;
    loading = false;
    error = '';

    tokenType = ERC20Type.Unlimited;
    tokenList: TERC20[] = [];
    chainId: ChainId = ChainId.Polygon;

    erc20Token: TERC20 | null = null;
    erc20TokenAddress = '';

    name = '';
    symbol = '';
    totalSupply = 0;
    logoImg: File | null = null;

    async submit() {
        try {
            this.loading = true;

            await this.$store.dispatch('erc20/create', {
                chainId: this.chainId,
                name: this.name,
                symbol: this.symbol,
                type: this.tokenType,
                totalSupply: this.tokenType === ERC20Type.Limited ? toWei(String(this.totalSupply)) : 0,
                file: this.logoImg,
            });

            this.$bvModal.hide(`modalERC20Create`);
        } catch (error) {
            throw error;
        } finally {
            this.loading = false;
        }
    }
}
