
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseModal from './BaseModal.vue';
import BaseCardRewardExpiry from '../cards/BaseCardRewardExpiry.vue';
import BaseCardRewardLimits from '../cards/BaseCardRewardLimits.vue';
import BaseCardQuestLocks from '../cards/BaseCardQuestLocks.vue';

@Component({
    components: {
        BaseModal,
        BaseCardQuestLocks,
        BaseCardRewardExpiry,
        BaseCardRewardLimits,
    },
})
export default class ModalRewardERC20Create extends Vue {
    title = '';
    description = '';
    expiryDate: Date | string = '';
    limit = 0;
    limitSupply = 0;
    pointPrice = 0;
    imageFile: File | null = null;
    image = '';
    isPromoted = false;
    isPublished = false;
    locks: TQuestLock[] = [];

    @Prop() id!: string;
    @Prop() error!: string;
    @Prop() isLoading!: boolean;
    @Prop() pool!: TPool;
    @Prop({ required: false }) reward!: TReward;

    onShow() {
        this.title = this.reward ? this.reward.title : this.title;
        this.description = this.reward ? this.reward.description : this.description;
        this.pointPrice = this.reward ? this.reward.pointPrice : this.pointPrice;
        this.expiryDate = this.reward ? this.reward.expiryDate : this.expiryDate;
        this.limit = this.reward ? this.reward.limit : this.limit;
        this.limitSupply = this.reward ? this.reward.limitSupply : this.limitSupply;
        this.image = this.reward && this.reward.image ? this.reward.image : '';
        this.isPromoted = this.reward ? this.reward.isPromoted : this.isPromoted;
        this.isPublished = this.reward ? this.reward.isPublished : this.isPublished;
        this.locks = this.reward ? this.reward.locks : this.locks;
        this.$emit('show');
    }

    onSubmit() {
        this.$emit('submit', {
            poolId: String(this.pool._id),
            title: this.title,
            description: this.description,
            pointPrice: this.pointPrice,
            expiryDate: this.expiryDate || '',
            limit: this.limit,
            limitSupply: this.limitSupply,
            file: this.imageFile,
            locks: this.locks,
            isPromoted: this.isPromoted,
            isPublished: this.isPublished,
        });
    }
}
