
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class IntegrationTwitterView extends Vue {
    @Prop() label!: string;
    @Prop() tooltip!: string;
    @Prop() prepend!: string;
    @Prop() fields!: string[];

    onInput(key: number, value: string) {
        const fields = this.fields;
        fields[key] = value;
        this.$emit('input', fields);
    }

    onClickRemove(key: number) {
        const fields = this.fields;
        fields.splice(key, 1);
        this.$emit('input', fields);
    }
}
