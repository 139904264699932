
import { Component, Prop, Vue } from 'vue-property-decorator';
import { providerIconMap } from '@thxnetwork/common/maps';
import { AccessTokenKind, AccountVariant } from '@thxnetwork/common/enums';
import { validateEmail } from '@thxnetwork/dashboard/utils/email';
import { mapGetters } from 'vuex';
import { AccountPlanType } from '@thxnetwork/common/enums';

@Component({
    computed: mapGetters({
        account: 'account/profile',
    }),
})
export default class BaseCardLeaderboard extends Vue {
    account!: TAccount;
    email = '';
    otp = '';
    error = '';
    providers = {
        [AccountVariant.SSOGoogle]: {
            kind: AccessTokenKind.Google,
            variant: AccountVariant.SSOGoogle,
            icon: providerIconMap[AccessTokenKind.Google],
            title: 'Sign in with Google',
            isLoading: false,
        },
        [AccountVariant.SSOTwitter]: {
            kind: AccessTokenKind.Twitter,
            variant: AccountVariant.SSOTwitter,
            icon: providerIconMap[AccessTokenKind.Twitter],
            title: 'Sign in with Twitter',
            isLoading: false,
        },
        [AccountVariant.SSODiscord]: {
            kind: AccessTokenKind.Discord,
            variant: AccountVariant.SSODiscord,
            icon: providerIconMap[AccessTokenKind.Discord],
            title: 'Sign in with Discord',
            isLoading: false,
        },
        [AccountVariant.SSOTwitch]: {
            kind: AccessTokenKind.Twitch,
            variant: AccountVariant.SSOTwitch,
            icon: providerIconMap[AccessTokenKind.Twitch],
            title: 'Sign in with Twitch',
            isLoading: false,
        },
        [AccountVariant.SSOGithub]: {
            kind: AccessTokenKind.Github,
            variant: AccountVariant.SSOGithub,
            icon: providerIconMap[AccessTokenKind.Github],
            title: 'Sign in with Github',
            isLoading: false,
        },
    };
    isLoadingOTP = false;
    isLoadingOTPVerify = false;
    isEmailSent = false;

    @Prop() readonly signup!: boolean;
    @Prop() readonly plan!: AccountPlanType;

    get isEmailValid() {
        if (!this.email) return false;
        return validateEmail(this.email);
    }

    get isOTPValid() {
        return this.otp.length === 6;
    }

    async onSubmitSigninWithOTP() {
        this.isLoadingOTP = true;
        try {
            await this.$store.dispatch('auth/signInWithOtp', { email: this.email, plan: this.plan });
            this.isEmailSent = true;
        } catch (error) {
            this.error = (error as Error).message;
        } finally {
            this.isLoadingOTP = false;
        }
    }

    async onSubmitVerifyOTP() {
        this.isLoadingOTPVerify = true;
        try {
            await this.$store.dispatch('auth/verifyOtp', { email: this.email, token: this.otp, plan: this.plan });
            if (!this.account) throw new Error('An issue occured while verifying OTP. Please try again.');
        } catch (error) {
            this.error = (error as Error).message;
        } finally {
            this.isLoadingOTPVerify = false;
        }
    }

    async onClickSigninWithOAuth(variant: AccountVariant) {
        this.providers[variant].isLoading = true;
        try {
            await this.$store.dispatch('auth/signinWithOAuth', {
                variant,
                plan: this.plan,
                skipBrowserRedirect: false,
            });
            if (!this.account) throw new Error('An issue occured while logging in. Please try again.');
        } catch (error) {
            this.error = (error as Error).message;
        } finally {
            this.providers[variant].isLoading = false;
        }
    }
}
