
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { getAddressURL } from '../../utils/chains';
import BaseParticipantAccount from '@thxnetwork/dashboard/components/BaseParticipantAccount.vue';
import BaseCardTableHeader from '@thxnetwork/dashboard/components/cards/BaseCardTableHeader.vue';
import BaseButtonQuestEntryStatus from '@thxnetwork/dashboard/components/buttons/BaseButtonQuestEntryStatus.vue';
import BaseModal from './BaseModal.vue';
import BaseParticipantConnectedAccount, {
    parseConnectedAccounts,
} from '@thxnetwork/dashboard/components/BaseParticipantConnectedAccount.vue';
import { TQuestEntryState } from '@thxnetwork/dashboard/store/modules/pools';
import { QuestEntryStatus } from '@thxnetwork/common/enums';

@Component({
    components: {
        BaseModal,
        BaseCardTableHeader,
        BaseParticipantAccount,
        BaseParticipantConnectedAccount,
        BaseButtonQuestEntryStatus,
    },
    computed: mapGetters({
        entriesList: 'pools/entries',
    }),
})
export default class BaseModalQuestEntries extends Vue {
    QuestEntryStatus = QuestEntryStatus;
    getAddressURL = getAddressURL;
    format = format;
    isCheckedAll = false;
    isLoading = false;
    selectedItems: TQuestEntry[] = [];
    entriesList!: TQuestEntryState;
    limit = 25;
    page = 1;
    actions = [
        { label: 'Approve all', variant: 0 },
        { label: 'Reject all', variant: 1 },
    ];

    @Prop() id!: string;
    @Prop() quest!: TQuestSocial;

    get questEntries(): {
        total: number;
        results: TQuestEntry[];
        meta?: { reachTotal?: number; participantCount?: number };
    } {
        if (!this.entriesList[this.quest.poolId]) return { total: 0, results: [] };
        if (!this.entriesList[this.quest.poolId][this.quest._id]) return { total: 0, results: [] };

        return this.entriesList[this.quest.poolId][this.quest._id];
    }

    get entries() {
        return this.questEntries.results
            .sort((a: TQuestEntry, b: TQuestEntry) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((entry: any) => ({
                checkbox: entry._id,
                status: entry.status,
                account: entry.account,
                tokens: entry.account && parseConnectedAccounts(entry.account),
                amount: entry.amount,
                metadata: entry.metadata,
                created: entry.createdAt,
                entry: entry as TQuestEntry,
            }));
    }

    async getEntries() {
        this.isLoading = true;
        await this.$store.dispatch('pools/listEntries', { quest: this.quest, page: this.page, limit: this.limit });
        this.isLoading = false;
    }

    onChangePage(page: number) {
        this.page = page;
        this.getEntries();
    }

    onChangeLimit(limit: number) {
        this.page = 1;
        this.limit = limit;
        this.getEntries();
    }

    onClickUpdate(entry: TQuestEntry, status: QuestEntryStatus) {
        this.$store.dispatch('pools/updateEntries', {
            quest: this.quest,
            entries: [{ entryId: entry._id, status }],
        });
    }

    onChecked(checked: boolean) {
        this.selectedItems = checked ? this.entriesList[this.quest.poolId][this.quest._id].results : [];
        this.isCheckedAll = checked;
    }

    async onClickAction(action: { variant: number }) {
        // 1. Approve all, 2. Reject all
        const mappers = {
            0: () =>
                this.$store.dispatch('pools/updateEntries', {
                    quest: this.quest,
                    entries: this.selectedItems.map((entry) => ({
                        entryId: entry._id,
                        status: QuestEntryStatus.Approved,
                    })),
                }),
            1: () =>
                this.$store.dispatch('pools/updateEntries', {
                    quest: this.quest,
                    entries: this.selectedItems.map((entry) => ({
                        entryId: entry._id,
                        status: QuestEntryStatus.Rejected,
                    })),
                }),
        };
        await mappers[action.variant]();
        this.isCheckedAll = false;
        this.selectedItems = [];
    }
}
