
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SettingsTwitterView extends Vue {
    childRoutes = [
        {
            name: 'Twitter',
            class: 'fab fa-twitter',
            route: 'twitter',
        },
        {
            name: 'Discord',
            class: 'fab fa-discord',
            route: 'discord',
        },
        // {
        //     name: 'Telegram',
        //     class: 'fab fa-telegram',
        //     route: 'telegram',
        // },
    ];

    @Prop() pool!: TPool;
}
