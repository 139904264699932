
import { DOCS_URL } from '@thxnetwork/dashboard/config/secrets';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

@Component({
    computed: {
        ...mapState('account', {
            isDarkModeEnabled: 'isDarkModeEnabled',
        }),
        ...mapGetters({
            account: 'account/profile',
        }),
    },
})
export default class BaseNavbarTop extends Vue {
    docsUrl = DOCS_URL;
    account!: TAccount;
    isDarkModeEnabled!: boolean;

    onClickDarkModeToggle() {
        this.$store.commit('account/setDarkMode', !this.isDarkModeEnabled);
    }

    async onClickSignout() {
        await this.$store.dispatch('auth/signOut');
        try {
            await this.$router.push({ name: 'login' });
        } catch (error) {
            // Ignore redundant navigation to current location error
        }
    }
}
