
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isAddress } from 'web3-utils';
import { ChainId, QuestVariant } from '@thxnetwork/common/enums';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { NODE_ENV } from '@thxnetwork/dashboard/config/secrets';
import BaseModal from '@thxnetwork/dashboard/components/modals/BaseModal.vue';
import BaseModalQuestCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestCreate.vue';

@Component({
    components: {
        BaseModal,
        BaseModalQuestCreate,
    },
})
export default class ModalQuestWeb3Create extends Vue {
    ChainId = ChainId;
    chainInfo = Object.values(chainInfo).filter((x) =>
        x.chainId === ChainId.Hardhat && NODE_ENV === 'production' ? false : true,
    );
    isAddress = isAddress;
    isLoading = false;
    isVisible = true;
    error = '';
    amount = 0;
    methodName = '';
    threshold = '0';
    contracts: { chainId: ChainId; address: string }[] = [{ chainId: ChainId.Polygon, address: '' }];

    @Prop() id!: string;
    @Prop() pool!: TPool;
    @Prop({ required: false }) quest!: TQuestWeb3;

    get isSubmitDisabled() {
        const isContractAddressInvalid = this.contracts.some((x) => !isAddress(x.address));
        return !this.contracts.length || isContractAddressInvalid || !this.methodName;
    }

    onShow() {
        this.amount = this.quest ? this.quest.amount : this.amount;
        this.contracts = this.quest ? this.quest.contracts : this.contracts;
        this.methodName = this.quest ? this.quest.methodName : this.methodName;
        this.threshold = this.quest ? this.quest.threshold : this.threshold;
    }

    async onSubmit(payload: TBaseQuest) {
        this.isLoading = true;
        try {
            await this.$store.dispatch(`pools/${this.quest ? 'updateQuest' : 'createQuest'}`, {
                ...this.quest,
                ...payload,
                variant: QuestVariant.Web3,
                amount: this.amount,
                methodName: this.methodName,
                threshold: this.threshold,
                contracts: JSON.stringify(this.contracts),
            });

            this.$emit('submit', { isPublished: payload.isPublished });
            this.$bvModal.hide(this.id);
        } catch (error: any) {
            this.error = error.message;
        } finally {
            this.isLoading = false;
        }
    }
}
