
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RewardVariant } from '@thxnetwork/common/enums';
import { CSVParser } from '../../utils/csv';
import BaseModalRewardCreate from './BaseModalRewardCreate.vue';
import BaseTableCouponCodes from '../tables/BaseTableCouponCodes.vue';

@Component({
    components: {
        BaseModalRewardCreate,
        BaseTableCouponCodes,
    },
})
export default class ModalRewardCustomCreate extends Vue {
    isLoading = false;
    error = '';

    fileCoupons: File | null = null;
    codes: string[] = [];
    webshopURL = '';

    @Prop() id!: string;
    @Prop() pool!: TPool;
    @Prop({ required: false }) reward!: TRewardCoupon;

    onShow() {
        this.codes = [];
        this.fileCoupons = null;
        this.webshopURL = this.reward ? this.reward.webshopURL : this.webshopURL;
    }

    onChangeFileCoupons(file: File) {
        CSVParser.parse(file, { complete: this.onComplete, error: console.error });
    }

    onComplete({ data, errors }: { data: string[]; errors: any[] }) {
        this.codes = data.map((code) => code[0]);
        if (errors.length) console.error(errors);
    }

    async onSubmit(payload: TReward) {
        this.isLoading = true;
        try {
            await this.$store.dispatch(`pools/${this.reward ? 'update' : 'create'}Reward`, {
                ...this.reward,
                ...payload,
                variant: RewardVariant.Coupon,
                codes: this.codes,
                webshopURL: this.webshopURL && this.webshopURL,
            });
            this.isLoading = false;
            this.$emit('submit', { isPublished: payload.isPublished });
            this.$bvModal.hide(this.id);
        } catch (error) {
            this.error = (error as Error).toString();
        } finally {
            this.isLoading = false;
        }
    }
}
