
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseDropdownEventType extends Vue {
    newEvent = '';

    @Prop() pool!: TPool;
    @Prop() eventName!: string;

    onClickSubmit() {
        this.$emit('click', this.newEvent);
        this.newEvent = '';
    }
}
