
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QuestVariant } from '@thxnetwork/common/enums';
import BaseModalQuestCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestCreate.vue';
import BaseDropdownWebhook from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownWebhook.vue';

@Component({
    components: {
        BaseModalQuestCreate,
        BaseDropdownWebhook,
    },
})
export default class ModalQuestWebhookCreate extends Vue {
    isLoading = false;
    isVisible = true;
    error = '';
    amount = 0;
    webhook: TWebhook | null = null;
    metadata = '';
    isAmountCustom = false;

    @Prop() id!: string;
    @Prop() pool!: TPool;
    @Prop({ required: false }) quest!: TQuestWebhook;

    onShow() {
        this.amount = this.quest ? this.quest.amount : this.amount;
        this.metadata = this.quest ? this.quest.metadata : this.metadata;
        this.isAmountCustom = this.quest ? this.quest.isAmountCustom : this.isAmountCustom;
        this.webhook = this.quest
            ? this.pool.webhooks.find((webhook) => webhook._id === this.quest.webhookId) || null
            : null;
    }

    get isSubmitDisabled() {
        return !this.webhook;
    }

    async onSubmit(payload: TBaseQuest) {
        this.isLoading = true;
        try {
            await this.$store.dispatch(`pools/${this.quest ? 'updateQuest' : 'createQuest'}`, {
                ...this.quest,
                ...payload,
                variant: QuestVariant.Webhook,
                amount: this.amount,
                webhookId: this.webhook?._id,
                metadata: this.metadata,
                isAmountCustom: this.isAmountCustom,
            });
            this.$bvModal.hide(this.id);
            this.$emit('submit', { isPublished: payload.isPublished });
        } catch (error: any) {
            this.error = error.message;
        } finally {
            this.isLoading = false;
        }
    }
}
