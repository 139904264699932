
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseModal from './BaseModal.vue';
import BaseIdenticon from '../BaseIdenticon.vue';
import BaseCampaignDuration from '@thxnetwork/dashboard/components/form-group/BaseDateDuration.vue';
import BaseFormGroup from '@thxnetwork/dashboard/components/form-group/BaseFormGroup.vue';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';

@Component({
    components: {
        chainInfo,
        BaseModal,
        BaseIdenticon,
        BaseCampaignDuration,
        BaseFormGroup,
    },
    computed: mapGetters({
        profile: 'account/profile',
    }),
})
export default class ModalAssetPoolCreate extends Vue {
    isLoading = false;
    error = '';
    poolVariant = 'defaultPool';
    profile!: TAccount;
    chainInfo = chainInfo;
    title = '';
    startDate: Date | null = null;
    endDate: Date | null = null;

    @Prop() id!: string;

    get isDisabled() {
        return this.isLoading || !this.title;
    }

    async submit() {
        this.isLoading = true;
        try {
            await this.$store.dispatch('pools/create', {
                title: this.title,
            });

            this.$bvModal.hide(this.id);
        } catch (error: any) {
            this.error = error.toString();
        } finally {
            this.isLoading = false;
        }
    }
}
