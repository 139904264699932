
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ERC721Variant, type TERC721 } from '@thxnetwork/dashboard/types/erc721';
import poll from 'promise-poller';
import BaseCard from '@thxnetwork/dashboard/components/cards/BaseCard.vue';
import BaseBadgeNetwork from '@thxnetwork/dashboard/components/badges/BaseBadgeNetwork.vue';
import BaseIdenticon from '@thxnetwork/dashboard/components/BaseIdenticon.vue';
import BaseDropdownMenuNft from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownMenuNft.vue';
import BaseModalPoolCreate from '@thxnetwork/dashboard/components/modals/BaseModalPoolCreate.vue';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { mapGetters } from 'vuex';
import BaseAnchorAddress from '../BaseAnchorAddress.vue';
import { TAccount } from '@thxnetwork/common/lib/types/interfaces';

@Component({
    components: {
        BaseModalPoolCreate,
        BaseCard,
        BaseBadgeNetwork,
        BaseIdenticon,
        BaseDropdownMenuNft,
        BaseAnchorAddress,
    },
    computed: mapGetters({
        account: 'account/profile',
    }),
})
export default class BaseCardERC721 extends Vue {
    ERC721Variant = ERC721Variant;
    isLoading = true;
    isDeploying = false;
    error = '';
    account!: TAccount;

    @Prop() erc721!: TERC721;

    get isOwner() {
        if (!this.account) return;
        return this.erc721.sub === this.account.sub;
    }

    openTokenUrl() {
        const url = `${chainInfo[this.erc721.chainId].blockExplorer}/token/${this.erc721.address}`;
        return (window as any).open(url, '_blank').focus();
    }

    async mounted() {
        await this.$store.dispatch('erc721/read', this.erc721._id);

        if (!this.erc721.address) {
            this.isDeploying = true;
            this.waitForAddress();
        } else {
            this.isDeploying = false;
            this.isLoading = false;
        }
    }

    waitForAddress() {
        const taskFn = async () => {
            const erc721 = await this.$store.dispatch('erc721/read', this.erc721._id);
            if (erc721 && erc721.address.length) {
                this.isDeploying = false;
                this.isLoading = false;
                return Promise.resolve(erc721);
            } else {
                this.isLoading = false;
                return Promise.reject(erc721);
            }
        };

        poll({ taskFn, interval: 3000, retries: 10 });
    }

    onClickMetadata() {
        this.$router.push({ path: `/nft/${this.erc721.variant}/${this.erc721._id}` });
    }

    async onClickDelete() {
        this.isLoading = true;
        await this.$store.dispatch('erc721/remove', this.erc721);
        this.isLoading = false;
    }
}
