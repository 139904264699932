
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseDropdownDiscordChannel from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownDiscordChannel.vue';

@Component({
    components: {
        BaseDropdownDiscordChannel,
    },
})
export default class BaseCardDiscordNotification extends Vue {
    isCollapsed = true;
    channelId = '';
    message = '';
    isEnabled = false;

    @Prop() variant!: string;
    @Prop() header!: string;
    @Prop() tooltip!: string;
    @Prop() guild!: TDiscordGuild;

    mounted() {
        this.channelId = this.guild.notifications[this.variant].channelId;
        this.message = this.guild.notifications[this.variant].message;
        this.isEnabled = this.guild.notifications[this.variant].isEnabled;
    }

    onClickChannel(channelId: string) {
        this.channelId = channelId;
        this.update();
    }

    onChangeMessage(message: string) {
        this.message = message;
        this.update();
    }

    onCheckEnabled(isEnabled: boolean) {
        this.isEnabled = isEnabled;
        this.update();
    }

    update() {
        this.guild.notifications[this.variant] = {
            channelId: this.channelId,
            message: this.message,
            isEnabled: this.isEnabled,
        };
        this.$emit('update', this.guild);
    }
}
