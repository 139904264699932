
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseFormGroupQuestLocks from '@thxnetwork/dashboard/components/form-group/BaseFormGroupQuestLocks.vue';

@Component({
    components: {
        BaseFormGroupQuestLocks,
    },
    computed: mapGetters({
        questList: 'pools/quests',
    }),
})
export default class BaseCardQuestLocks extends Vue {
    isVisible = true;

    @Prop() pool!: TPool;
    @Prop() locks!: TQuestLock[];
}
