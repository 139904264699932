var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"d-flex align-items-center"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Quests")])]),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-dropdown',{attrs:{"no-caret":"","variant":"primary","toggle-class":"rounded-pill","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-plus mr-2 ml-0"}),_vm._v(" New Quest ")]},proxy:true}])},[_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"width":"650px"}},_vm._l((Object.keys(_vm.QuestVariant).filter((v) => isNaN(Number(v)))),function(variant,key){return _c('b-dropdown-item-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(`${_vm.questModalComponentMap[_vm.QuestVariant[variant]]}-${variant}`),expression:"`${questModalComponentMap[QuestVariant[variant]]}-${variant}`"}],key:key,staticStyle:{"flex":"1 0 50%"},attrs:{"button-class":"d-flex px-2"}},[_c('b-media',{scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"p-3 rounded d-flex align-items-center justify-content-center",staticStyle:{"width":"50px"},style:({
                                        'background-color': _vm.contentQuests[`${variant.toLowerCase()}-quest`].color,
                                    }),attrs:{"title":_vm.contentQuests[`${variant.toLowerCase()}-quest`].description}},[_c('i',{staticClass:"text-white",class:_vm.contentQuests[`${variant.toLowerCase()}-quest`].icon})])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.contentQuests[`${variant.toLowerCase()}-quest`].tag)+" "),_c('p',{staticClass:"text-muted small mb-0"},[_vm._v(" "+_vm._s(_vm.contentQuests[`${variant.toLowerCase()}-quest`].title)+" ")]),_c(_vm.questModalComponentMap[_vm.QuestVariant[variant]],{tag:"component",attrs:{"variant":variant,"id":`${_vm.questModalComponentMap[_vm.QuestVariant[variant]]}-${variant}`,"total":_vm.allQuests.length,"pool":_vm.pool},on:{"submit":_vm.onSubmit}})],1)],1)}),1)])],1)],1),_c('BCard',{attrs:{"variant":"white","body-class":"p-0 shadow-sm"}},[_c('BaseCardTableHeader',{attrs:{"page":_vm.page,"limit":_vm.limit,"pool":_vm.pool,"total-rows":_vm.total,"selectedItems":_vm.selectedItems,"actions":_vm.actions,"published":_vm.isPublished},on:{"click-published":_vm.onClickFilterPublished,"click-action":_vm.onClickAction,"change-page":_vm.onChangePage,"change-limit":_vm.onChangeLimit}}),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover",attrs:{"id":"table-quests"}},[_c('thead',[_c('tr',[_c('th',[_vm._v(" ")]),_c('th',[_c('b-form-checkbox',{attrs:{"checked":_vm.isCheckedAll},on:{"change":_vm.onChecked}})],1),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Points")]),_c('th',[_vm._v("Entries")]),_c('th',[_vm._v("Created")]),_c('th',[_vm._v(" ")])])]),_c('draggable',{attrs:{"tag":"tbody",",":""},on:{"start":function($event){_vm.isDragging = true},"end":function($event){_vm.isDragging = false}},model:{value:(_vm.allQuests),callback:function ($$v) {_vm.allQuests=$$v},expression:"allQuests"}},_vm._l((_vm.allQuests),function(item){return _c('tr',{key:item.quest._id,staticClass:"w-100",class:{
                            'bg-light text-gray': !item.quest.isPublished,
                            'text-gray': _vm.isDragging,
                        },staticStyle:{"cursor":"move"},attrs:{"items":_vm.allQuests}},[_c('td',{staticStyle:{"padding":"0","vertical-align":"top","position":"relative"}},[_c('div',{staticClass:"bg-light d-flex justify-content-center align-items-center h-100",staticStyle:{"width":"40px","position":"absolute"}},[_c('i',{staticClass:"text-muted small",class:_vm.questIconClassMap[item.quest.variant]})])]),_c('td',[_c('b-form-checkbox',{attrs:{"value":item.quest},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('td',[_vm._v(" "+_vm._s(item.title.label)+" "),(item.title.locks.length)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fas fa-lock mx-1 text-muted",attrs:{"title":`Quest locks: ${item.quest.locks.length} quest${
                                    item.quest.locks.length > 1 ? 's' : ''
                                }`}}):_vm._e(),(item.quest.expiryDate)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fas fa-clock small ml-1",class:{
                                    'text-danger': item.title.expiry.isExpired,
                                    'text-muted': !item.title.expiry.isExpired,
                                },attrs:{"title":`Expiry: ${item.title.expiry.label}`}}):_vm._e()]),_c('td',[_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(item.points)+" ")])]),_c('td',[(
                                    [
                                        _vm.QuestVariant.Daily,
                                        _vm.QuestVariant.Invite,
                                        _vm.QuestVariant.Twitter,
                                        _vm.QuestVariant.YouTube,
                                        _vm.QuestVariant.Discord,
                                        _vm.QuestVariant.Custom,
                                        _vm.QuestVariant.Web3,
                                        _vm.QuestVariant.Gitcoin,
                                        _vm.QuestVariant.Webhook,
                                    ].includes(item.quest.variant)
                                )?_c('BaseButtonQuestEntries',{attrs:{"pool":_vm.pool,"quest":item.quest}}):_vm._e()],1),_c('td',[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.created))])]),_c('td',[_c('b-dropdown',{attrs:{"variant":"link","size":"sm","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-h ml-0 text-muted"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(_vm.questModalComponentMap[item.quest.variant] + item.quest._id),expression:"questModalComponentMap[item.quest.variant] + item.quest._id"}]},[_vm._v(" Edit ")]),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(`modalDelete-${item.quest._id}`),expression:"`modalDelete-${item.quest._id}`"}]},[_vm._v(" Delete ")])],1),_c(_vm.questModalComponentMap[item.quest.variant],{key:item.quest._id,tag:"component",attrs:{"id":_vm.questModalComponentMap[item.quest.variant] + item.quest._id,"pool":_vm.pool,"total":_vm.allQuests.length,"quest":_vm.quests[_vm.$route.params.id].results.find((q) => q._id === item.quest._id)},on:{"submit":_vm.onSubmit}}),_c('BaseModalDelete',{attrs:{"id":`modalDelete-${item.quest._id}`,"subject":item.quest.title},on:{"submit":function($event){return _vm.onClickDelete(item.quest)}}})],1)])}),0)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }