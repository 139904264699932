
import { Component, Prop, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';

const iconMap = {
    retweet_count: 'fas fa-retweet',
    reply_count: 'fas fa-reply',
    like_count: 'fas fa-heart',
    quote_count: 'fas fa-quote-right',
    bookmark_count: 'fas fa-bookmark',
    impression_count: 'fas fa-eye',
};

@Component({})
export default class BaseCardInfoLinks extends Vue {
    isVisible = false;
    format = format;
    iconMap = iconMap;

    @Prop() post!: TTwitterPostResponse & { user: TTwitterUserResponse; media: TTwitterMediaResponse[] };

    openURL(path: string) {
        window.open(path, '_blank');
    }
}
