
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { QuestVariant } from '@thxnetwork/common/enums';
import { TQuestState } from '@thxnetwork/dashboard/store/modules/pools';
import { contentQuests } from '@thxnetwork/common/constants';
import BaseModalQuestDailyCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestDailyCreate.vue';
import BaseModalQuestSocialCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestSocialCreate.vue';
import BaseModalQuestInviteCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestInviteCreate.vue';
import BaseModalQuestCustomCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestCustomCreate.vue';
import BaseModalQuestWeb3Create from '@thxnetwork/dashboard/components/modals/BaseModalQuestWeb3Create.vue';
import BaseModalQuestInviteClaims from '@thxnetwork/dashboard/components/modals/BaseModalQuestInviteClaims.vue';
import BaseModalQuestGitcoinCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestGitcoinCreate.vue';
import BaseModalQuestWebhookCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestWebhookCreate.vue';
import BaseCardTableHeader from '@thxnetwork/dashboard/components/cards/BaseCardTableHeader.vue';
import BaseButtonQuestEntries from '@thxnetwork/dashboard/components/buttons/BaseButtonQuestEntries.vue';
import BaseModalDelete from '@thxnetwork/dashboard/components/modals/BaseModalDelete.vue';
import draggable from 'vuedraggable';

@Component({
    components: {
        BaseCardTableHeader,
        BaseButtonQuestEntries,
        BaseModalQuestDailyCreate,
        BaseModalQuestSocialCreate,
        BaseModalQuestCustomCreate,
        BaseModalQuestWeb3Create,
        BaseModalQuestGitcoinCreate,
        BaseModalQuestInviteCreate,
        BaseModalQuestWebhookCreate,
        BaseModalQuestInviteClaims,
        BaseModalDelete,
        draggable,
    },
    computed: mapGetters({
        quests: 'pools/quests',
    }),
})
export default class QuestsView extends Vue {
    contentQuests = contentQuests;
    actions = [
        { label: 'Publish all', variant: 0 },
        { label: 'Unpublish all', variant: 1 },
        { label: 'Delete all', variant: 2 },
    ];
    isDragging = false;
    isLoading = true;
    limit = 25;
    page = 1;
    isCheckedAll = false;
    selectedItems: TQuest[] = [];
    QuestVariant = QuestVariant;
    questModalComponentMap = {
        [QuestVariant.Daily]: 'BaseModalQuestDailyCreate',
        [QuestVariant.Invite]: 'BaseModalQuestInviteCreate',
        [QuestVariant.Twitter]: 'BaseModalQuestSocialCreate',
        [QuestVariant.YouTube]: 'BaseModalQuestSocialCreate',
        [QuestVariant.Discord]: 'BaseModalQuestSocialCreate',
        [QuestVariant.Custom]: 'BaseModalQuestCustomCreate',
        [QuestVariant.Web3]: 'BaseModalQuestWeb3Create',
        [QuestVariant.Gitcoin]: 'BaseModalQuestGitcoinCreate',
        [QuestVariant.Webhook]: 'BaseModalQuestWebhookCreate',
    };
    questIconClassMap = {
        [QuestVariant.Daily]: 'fas fa-calendar',
        [QuestVariant.Invite]: 'fas fa-comments',
        [QuestVariant.Twitter]: 'fab fa-twitter',
        [QuestVariant.Discord]: 'fab fa-discord',
        [QuestVariant.YouTube]: 'fab fa-youtube',
        [QuestVariant.Custom]: 'fas fa-flag',
        [QuestVariant.Web3]: 'fab fa-ethereum',
        [QuestVariant.Gitcoin]: 'fas fa-fingerprint',
        [QuestVariant.Webhook]: 'fas fa-globe',
    };
    isPublished = true;

    quests!: TQuestState;

    @Prop() pool!: TPool;

    get isBusy() {
        return this.isDragging || this.isLoading;
    }

    get total() {
        if (!this.quests[this.$route.params.id]) return 0;
        return this.quests[this.$route.params.id].total;
    }

    get allQuests() {
        if (!this.quests[this.$route.params.id]) return [];
        return this.quests[this.$route.params.id].results
            .map((quest: any) => ({
                index: quest.index,
                checkbox: quest._id,
                title: {
                    label: quest.title,
                    locks: quest.locks,
                    expiry: {
                        isExpired: quest.expiryDate ? Date.now() > new Date(quest.expiryDate).getTime() : false,
                        label: quest.expiryDate ? format(new Date(quest.expiryDate), 'dd-MM-yyyy HH:mm') : '',
                    },
                },
                points: quest.amounts ? `${quest.amounts.length} days` : quest.amount,
                entries: quest.entryCount,
                created: format(new Date(quest.createdAt), 'dd-MM-yyyy HH:mm'),
                quest,
            }))
            .sort((a, b) => a.index - b.index);
    }

    set allQuests(items) {
        this.$store.dispatch('pools/sortQuests', {
            pool: this.pool,
            quests: JSON.stringify(items.map((item) => ({ variant: item.quest.variant, questId: item.quest._id }))),
            page: this.page,
            limit: this.limit,
            isPublished: this.isPublished,
        });
    }

    get sortableOptions() {
        return {
            chosenClass: 'is-selected',
        };
    }

    async mounted() {
        const { isPublished } = this.$route.query as { isPublished?: string };
        this.isPublished = isPublished ? JSON.parse(isPublished) : true;
        await this.listQuests();
    }

    async listQuests() {
        this.isLoading = true;
        await this.$store.dispatch('pools/listQuests', {
            page: this.page,
            pool: this.pool,
            limit: this.limit,
            isPublished: this.isPublished,
        });
        this.isLoading = false;
    }

    async openPublished(isPublished: boolean) {
        try {
            this.isPublished = isPublished;
            await this.$router.push({
                name: `quests`,
                params: {
                    id: this.pool._id,
                },
                query: { isPublished: isPublished ? String(isPublished) : 'false' },
            });
        } catch (error) {
            // Suppress error
        } finally {
            await this.listQuests();
        }
    }

    onSubmit(query: { isPublished: boolean }) {
        this.openPublished(query.isPublished);
    }

    async onClickFilterPublished(value: boolean) {
        this.openPublished(value);
        await this.listQuests();
    }

    onChangeLimit(limit: number) {
        this.limit = limit;
        this.listQuests();
    }

    onChecked(checked: boolean) {
        this.selectedItems = checked ? this.quests[this.$route.params.id].results : [];
        this.isCheckedAll = checked;
    }

    onChangePage(page: number) {
        this.page = page;
        this.listQuests();
    }

    onClickDelete(quest: TQuest) {
        this.$store.dispatch('pools/removeQuest', quest);
    }

    async onClickAction(action: { variant: number }) {
        // 1. Publish, 2. Unpublish, 3. Delete
        const mappers = {
            0: (quest) => this.$store.dispatch('pools/updateQuest', { ...quest, isPublished: true }),
            1: (quest) => this.$store.dispatch('pools/updateQuest', { ...quest, isPublished: false }),
            2: (quest) => this.$store.dispatch('pools/removeQuest', quest),
        };
        await Promise.all(this.selectedItems.map(mappers[action.variant]));
        this.isCheckedAll = false;
        this.selectedItems = [];
        this.listQuests();
    }
}
