
import { Goal, Role } from '@thxnetwork/common/enums';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { goalLabelMap, roleLabelMap } from '@thxnetwork/common/constants';
import { isValidUrl } from '@thxnetwork/dashboard/utils/url';
import { validateEmail } from '@thxnetwork/dashboard/utils/email';
import BaseCodeExample from '@thxnetwork/dashboard/components/BaseCodeExample.vue';

@Component({
    components: {
        BaseCodeExample,
    },
    computed: mapGetters({
        account: 'account/profile',
        pools: 'pools/all',
    }),
})
export default class BaseModalOnboarding extends Vue {
    Roles = Role;
    Goals = Goal;
    roleLabelMap = roleLabelMap;
    goalLabelMap = goalLabelMap;
    error = '';
    email = '';
    website = '';
    role: Role = Role.None;
    goal: Goal[] = [];
    isLoadingSubmit = false;

    pools!: IPools;
    account!: TAccount;

    get pool() {
        const pools = Object.values(this.pools || {});
        if (!pools.length) return;
        return pools[0];
    }

    async mounted() {
        await this.$store.dispatch('pools/list');

        if (!this.account.website || !this.account.email || !this.account.role || !this.account.goal.length) {
            this.$bvModal.show('modalRequestAccountEmailUpdate');
        }

        this.email = this.account.email;
        this.website = this.account.website;
        this.role = this.account.role || Role.None;
        this.goal = this.account.goal;
    }

    get isSubmitDisabled() {
        return !this.isValidWebsite || this.role === Role.None || !this.goal.length || this.isLoadingSubmit;
    }

    get isValidEmail() {
        return !!validateEmail(this.email);
    }

    get isValidWebsite() {
        if (!this.website) return;
        return isValidUrl('https://' + this.website);
    }

    async onClickSubmit() {
        if (this.isSubmitDisabled || this.isLoadingSubmit || !this.isValidWebsite) return;
        try {
            this.isLoadingSubmit = true;
            await this.$store.dispatch('account/update', {
                website: 'https://' + this.website,
                role: this.role,
                goal: this.goal,
                email: this.email,
            });
            this.$bvModal.hide('modalRequestAccountEmailUpdate');
        } catch (error) {
            const { response } = error as any;
            this.error = response && response.data.error.message;
        } finally {
            this.isLoadingSubmit = false;
        }
    }

    async onClickSignout() {
        await this.$store.dispatch('auth/signOut');
        try {
            await this.$router.push({ name: 'login' });
        } catch (error) {
            // Ignore redundant navigation to current location error
        }
    }
}
