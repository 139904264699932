
import { Component, Prop, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';
import BaseCardTwitterPost from '@thxnetwork/dashboard/components/cards/BaseCardTwitterPost.vue';
import { TwitterQuery } from '@thxnetwork/common/twitter';

@Component({
    components: {
        BaseCardTwitterPost,
    },
})
export default class BaseCardTwitterPostPreviews extends Vue {
    format = format;
    isLoading = false;
    posts: TTwitterPostWithUserAndMedia[] = [];

    @Prop() operators!: TTwitterOperators;

    get query() {
        const operators = TwitterQuery.stringify(this.operators);
        const operatorsObj = TwitterQuery.parse(operators);
        return TwitterQuery.create(operatorsObj);
    }

    async onClickSearch() {
        this.isLoading = true;
        try {
            const operators = TwitterQuery.stringify(this.operators);
            const posts = await this.$store.dispatch('account/searchTweets', {
                data: { operators },
            });
            this.posts = posts;
        } catch (error) {
            throw error;
        } finally {
            this.isLoading = false;
        }
    }
}
