
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseDropdownYoutubeChannels extends Vue {
    channelId = '';

    @Prop() content!: string;

    mounted() {
        this.channelId = this.content;
    }

    onChangeInput(content: string) {
        this.channelId = content;
        this.$emit('selected', { content, contentMetadata: { channelId: this.channelId } });
    }
}
