
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseListStateEmpty extends Vue {
    @Prop() textSubmit!: string;
    @Prop() title!: string;
    @Prop() description!: string;
    @Prop() disabled!: boolean;
}
