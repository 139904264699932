
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseModal from '@thxnetwork/dashboard/components/modals/BaseModal.vue';

@Component({
    components: {
        BaseModal,
    },
})
export default class BaseModalParticipantExport extends Vue {
    isLoading = false;
    error = '';

    @Prop() id!: string;
    @Prop() pool!: TPool;

    get isDisabled() {
        return this.isLoading;
    }

    onShow() {
        this.error = '';
    }

    async onClick() {
        try {
            this.isLoading = true;
            await this.$store.dispatch('pools/exportParticipants', this.pool);
            this.$emit('hidden');
            this.$bvModal.hide(this.id);
        } catch (error) {
            this.error = (error as Error).toString();
        } finally {
            this.isLoading = false;
        }
    }
}
