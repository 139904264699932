
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import BaseCardLogin from '@thxnetwork/dashboard/components/cards/BaseCardLogin.vue';

@Component({
    components: {
        BaseCardLogin,
    },
    computed: mapState('auth', {
        isReady: 'isReady',
    }),
})
export default class App extends Vue {
    isReady!: boolean;
}
