
import { ChainId, AccountPlanType } from '@thxnetwork/common/enums';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { contractNetworks, planPricingMap } from '@thxnetwork/common/constants';
import { toFiatPrice } from '@thxnetwork/dashboard/utils/price';
import { plans } from '@thxnetwork/dashboard/utils/plans';
import { mapGetters } from 'vuex';
import { TERC20AllowanceState, TERC20BalanceState } from '@thxnetwork/dashboard/types/erc20';
import { BigNumber, ethers } from 'ethers';
import { formatUnits, parseUnits, poll } from 'ethers/lib/utils';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { TWalletState } from '@thxnetwork/dashboard/store/modules/pools';
import BaseModal from './BaseModal.vue';

@Component({
    components: {
        BaseModal,
    },
    computed: mapGetters({
        walletList: 'pools/wallets',
        erc20BalanceList: 'erc20/balances',
        erc20AllowanceList: 'erc20/allowances',
    }),
})
export default class BaseModalPaymentCreate extends Vue {
    error = '';
    plan = AccountPlanType.Premium;
    AccountPlanType = AccountPlanType;
    duration = 12;
    planPricingMap = planPricingMap;
    toFiatPrice = toFiatPrice;
    plans = plans;
    erc20BalanceList!: TERC20BalanceState;
    erc20AllowanceList!: TERC20AllowanceState;
    parseUnits = parseUnits;
    isLoading = false;
    isChecked = false;
    isPolling = false;
    chainInfo = chainInfo;
    walletList!: TWalletState;

    @Prop() id!: string;
    @Prop() pool!: TPool;

    get chain() {
        if (!this.pool || !this.wallet) return { name: 'Unknown', blockExplorer: '' };
        return chainInfo[this.wallet.chainId];
    }

    get wallet() {
        if (!this.pool || !this.walletList[this.pool._id]) return;
        return this.walletList[this.pool._id].find((w) => w.chainId === ChainId.Polygon);
    }

    get paymentAmount() {
        // amount of weeks * sub costs / 4 weeks / 100 (correct decimals)
        return this.duration * (planPricingMap[this.plan].costSubscription / 4 / 100);
    }

    get isInsufficientAllowance() {
        if (!this.allowanceInWei) return true;
        const amount = parseUnits(this.paymentAmount.toString(), 'ether');
        return BigNumber.from(this.allowanceInWei).lt(amount);
    }

    get isInsufficientBalance() {
        if (!this.balanceInWei) return true;
        const amount = parseUnits(this.paymentAmount.toString(), 6);
        return BigNumber.from(this.balanceInWei).lt(amount);
    }

    get balanceInWei() {
        if (!this.wallet || !this.erc20BalanceList[this.addresses.USDC]) return '';
        return this.erc20BalanceList[this.addresses.USDC][this.wallet.address];
    }

    get allowanceInWei() {
        if (!this.wallet || !this.erc20AllowanceList[this.addresses.USDC]) return '';
        if (!this.erc20AllowanceList[this.addresses.USDC][this.wallet.address]) return '';
        return this.erc20AllowanceList[this.addresses.USDC][this.wallet.address][this.addresses.THXPaymentSplitter];
    }

    get balanceInUSD() {
        if (!this.balanceInWei) return toFiatPrice(0);
        const balance = formatUnits(this.balanceInWei, 6);
        return toFiatPrice(Number(balance));
    }

    get addresses() {
        if (!this.wallet) return '';
        return contractNetworks[this.wallet.chainId];
    }

    async getBalance() {
        await this.$store.dispatch('erc20/balanceOf', {
            wallet: this.wallet,
            tokenAddress: this.addresses.USDC,
        });
    }

    async getAllowance() {
        await this.$store.dispatch('erc20/allowance', {
            wallet: this.wallet,
            tokenAddress: this.addresses.USDC,
            spender: this.addresses.THXPaymentSplitter,
        });
    }

    async approve() {
        await this.$store.dispatch('erc20/approve', {
            wallet: this.wallet,
            tokenAddress: this.addresses.USDC,
            spender: this.addresses.THXPaymentSplitter,
            amountInWei: ethers.constants.MaxUint256.toString(),
        });
    }

    async waitForAllowance() {
        await poll(
            async () => {
                await this.getAllowance();
                if (!this.isInsufficientAllowance) {
                    return true;
                }
            },
            { interval: 1000, timeout: 60000 },
        );
    }

    onShow() {
        // this.getBalance();
    }

    async onClickVerify() {
        this.isLoading = true;
        await this.getBalance();
        await this.getAllowance();

        // Assert if allowance is less then payment amount
        if (BigNumber.from(this.allowanceInWei).lt(this.paymentAmount)) {
            try {
                this.isPolling = true;

                await this.approve();
                await this.waitForAllowance();

                this.isPolling = false;
            } catch (error) {
                this.error = (error as any).toString();
            } finally {
                this.isPolling = false;
            }
        }

        this.isLoading = false;
        this.isChecked = true;
    }

    async onClickComplete() {
        const amountInWei = parseUnits(this.paymentAmount.toString(), 6).toString();

        await this.$store.dispatch('pools/createPayment', {
            pool: this.pool,
            amountInWei,
            planType: this.plan,
        });
    }
}
