
import BaseModal from './BaseModal.vue';
import JSZip from 'jszip';
import QRCode from 'qrcode';
import QRCodeSVG from 'qrcode-svg';
import xml2js from 'xml2js';
import { jsPDF } from 'jspdf';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { API_URL, BASE_URL } from '@thxnetwork/dashboard/config/secrets';
import { saveAs } from 'file-saver';
import { loadImage } from '@thxnetwork/dashboard/utils/loadImage';
import { format } from 'date-fns';
import { TQRCodeEntryState } from '@thxnetwork/dashboard/store/modules/qrcodes';
import { mapGetters } from 'vuex';
import BaseParticipantAccount from '../BaseParticipantAccount.vue';

const unitList = [
    { label: 'Pixels', value: 'px' },
    { label: 'Centimeters', value: 'cm' },
    { label: 'Inch', value: 'in' },
    { label: 'Millimeters', value: 'mm' },
];
type UnitValues = 'px' | 'cm' | 'in' | 'mm';

const acceptedUnits: { [format: string]: string[] } = {
    png: ['px'],
    pdf: ['px', 'cm', 'in', 'mm'],
};

function hex2Rgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) as string[];
    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    };
}

@Component({
    name: 'BaseModalQRCodes',
    components: {
        BaseParticipantAccount,
        BaseModal,
        BaseCardTableHeader: () => import('@thxnetwork/dashboard/components/cards/BaseCardTableHeader.vue'),
    },
    computed: mapGetters({
        qrCodeEntryList: 'qrcodes/all',
    }),
})
export default class BaseModalQRCodes extends Vue {
    format = format;
    isSubmitDisabled = false;
    isLoadingAll = false;
    isLoading = false;
    color = '000000';
    size = 256;
    file: File | null = null;
    isCopied: { [id: string]: boolean } = {};

    selectedFormat = 'png';
    selectedUnit = unitList[0];
    selectedQRCodeEntries: string[] = [];
    allCodes = [];

    limit = 25;
    page = 1;
    index = 0;
    tabIndex = 0;
    claimAmount = 0;
    redirectURL = '';

    qrCodeEntryList!: TQRCodeEntryState;

    @Prop() id!: string;
    @Prop() reward!: TRewardNFT;
    @Prop() pool!: TPool;

    get qrCodes() {
        if (!this.qrCodeEntryList || !this.qrCodeEntryList[this.reward._id])
            return { total: 0, results: [], meta: { participantCount: 0 } };
        return this.qrCodeEntryList[this.reward._id];
    }

    get qrCodeEntries() {
        return this.qrCodes.results.map((entry: TQRCodeEntry) => ({
            checkbox: entry.uuid,
            url: this.getUrl(entry.uuid),
            account: entry.account,
            claimedAt: entry.claimedAt,
            createdAt: entry.createdAt,
            entry,
        }));
    }

    get units() {
        return unitList.filter((u) => acceptedUnits[this.selectedFormat].includes(u.value));
    }

    onShow() {
        this.listEntries();
        this.claimAmount = 0;
        this.redirectURL = this.getRedirectURL();
    }

    getRedirectURL() {
        const url = new URL(BASE_URL);
        url.pathname = `/preview/${this.pool._id}`;
        return url.toString();
    }

    async onClickCreate() {
        this.isLoading = true;
        await this.$store.dispatch('qrcodes/create', {
            rewardId: this.reward._id,
            claimAmount: this.claimAmount,
            redirectURL: this.redirectURL,
        });
        await this.listEntries();
        this.tabIndex = 1;
    }

    async listEntries() {
        this.isLoading = true;
        this.page = 1;
        await this.$store.dispatch('qrcodes/list', { reward: this.reward, page: this.page, limit: this.limit });
        this.isLoading = false;
    }

    onChangeRedirectURL() {
        this.listEntries();
    }

    onSelectAll(isSelectAll: boolean) {
        this.selectedQRCodeEntries = isSelectAll ? this.qrCodeEntries.map((e) => e.entry.uuid) : [];
    }

    onChangeLimit(limit: number) {
        this.limit = limit;
        this.listEntries();
    }

    onChangePage(page: number) {
        this.page = page;
        this.listEntries();
    }

    async listAll() {
        this.allCodes = await this.$store.dispatch('qrcodes/listAll', { reward: this.reward, page: 1, limit: 5000 });
    }

    async onClickDownloadZipAll() {
        this.isLoadingAll = true;

        // Fetch all codes (max 5000)
        await this.listAll();

        // Create the zip
        await this.onClickCreateZip(this.allCodes);

        this.isLoadingAll = false;
    }

    async onClickDownloadCSVAll() {
        this.isLoadingAll = true;
        // Fetch all codes (max 5000)
        await this.listAll();

        // Create the CSV
        this.onClickCreateCSV(this.allCodes);

        this.isLoadingAll = false;
    }

    onClickDelete(uuid: string) {
        this.removeQRCode(uuid);
    }

    async removeQRCode(uuid: string) {
        await this.$store.dispatch('qrcodes/remove', uuid);
    }

    async onClickAction(action: { variant: number; label: string }) {
        switch (action.variant) {
            case 0:
                this.isLoading = true;
                for (const uuid of Object.values(this.selectedQRCodeEntries)) {
                    await this.removeQRCode(uuid);
                }
                await this.listEntries();
                this.isLoading = false;
                break;
            case 1:
                this.onClickCreateZip(this.selectedQRCodeEntries);
                break;
            case 2:
                this.onClickCreateCSV(this.selectedQRCodeEntries);
                break;
        }
    }

    async createQRCode(url: string, size: number, color: string) {
        const imgSize = (size / 4) * 1.1;
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;

        await QRCode.toCanvas(canvas, url, {
            errorCorrectionLevel: 'H',
            margin: 0,
            color: {
                dark: color,
                light: '#ffffff',
            },
            width: size,
        });

        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        const img = !this.file
            ? await loadImage(BASE_URL + '/assets/qr-logo.jpg')
            : await loadImage(URL.createObjectURL(this.file));
        const positionX = ctx.canvas.height / 2 - imgSize / 2;
        const positionY = ctx.canvas.width / 2 - imgSize / 2;

        ctx.drawImage(img, positionX, positionY, imgSize, imgSize);

        const qrCode = canvas.toDataURL('image/png');

        return qrCode.replace(/^data:image\/png;base64,/, '');
    }

    async createQRCodeSvg(url: string, size: number, color: string, unit) {
        const qrcode = new QRCodeSVG({
            content: url,
            margin: 0,
            padding: 0,
            ecl: 'M',
        });
        qrcode.options.height = size;
        qrcode.options.width = size;

        const imgSize = size / 4;
        const positionX = size / 2 - imgSize / 2;
        const positionY = size / 2 - imgSize / 2;
        const svg = qrcode.svg();
        const xml = await xml2js.parseStringPromise(svg);

        const pdf = new jsPDF({ unit, format: [size, size] });
        for (let i = 1; i < xml.svg.rect.length; i++) {
            const rect = xml.svg.rect[i].$;
            const rgb = hex2Rgb(color);

            pdf.setFillColor(rgb.r, rgb.g, rgb.b);
            pdf.rect(rect.x, rect.y, rect.width, rect.height, 'F');
        }

        const img = !this.file
            ? await loadImage(BASE_URL + '/assets/qr-logo.jpg')
            : await loadImage(URL.createObjectURL(this.file));
        pdf.addImage(img.src, 'JPG', positionX, positionY, imgSize, imgSize, '', 'NONE');

        return pdf.output('arraybuffer');
    }

    getUrl(uuid: string) {
        const url = new URL(API_URL);
        url.pathname = `/v1/qr-codes/r/${uuid}`;
        return url.toString();
    }

    async onClickCreateZip(codes: string[]) {
        const filename = `${new Date().getTime()}_${this.pool._id}_claim_qr_codes`;
        const zip = new JSZip();
        const archive = zip.folder(filename) as JSZip;
        const format = this.selectedFormat;

        for (const uuid of codes) {
            let data: string | ArrayBuffer;
            const url = this.getUrl(uuid);

            switch (format) {
                case 'pdf': {
                    data = await this.createQRCodeSvg(
                        url,
                        this.size,
                        this.color,
                        this.selectedUnit.value as UnitValues,
                    );
                    archive.file(`${uuid}.pdf`, data, { base64: true });
                    break;
                }
                case 'png': {
                    data = await this.createQRCode(url, this.size, this.color);
                    archive.file(`${uuid}.png`, data, { base64: true });
                    break;
                }
            }
            this.index++;
        }

        await zip.generateAsync({ type: 'blob' }).then((content) => saveAs(content, `${filename}.zip`));
        this.index = 0;
    }

    onClickCreateCSV(codes: string[]) {
        const filename = `${new Date().getTime()}_${this.pool._id}_claim_urls`;
        const data = codes.map((uuid) => [this.getUrl(uuid)]);
        const csvContent = 'data:text/csv;charset=utf-8,' + data.map((e) => e.join(',')).join('\n');
        saveAs(encodeURI(csvContent), `${filename}.csv`);
    }
}
