
import { Component, Prop, Vue } from 'vue-property-decorator';
import { type TPool } from '@thxnetwork/types/interfaces';
import { mapGetters } from 'vuex';
import BaseNothingHere from '@thxnetwork/dashboard/components/BaseListStateEmpty.vue';
import BaseCardTableHeader from '@thxnetwork/dashboard/components/cards/BaseCardTableHeader.vue';
import { TQuestInviteEntryAccount, TRewardClaimState } from '@thxnetwork/dashboard/store/modules/referralRewardClaims';

import BaseModal from './BaseModal.vue';
import { type TQuestInvite } from '@thxnetwork/types/interfaces/QuestInvite';
import { format } from 'date-fns';

@Component({
    components: {
        BaseModal,
        BaseNothingHere,
        BaseCardTableHeader,
    },
    computed: mapGetters({
        totals: 'referralRewardClaims/totals',
        referralRewardClaims: 'referralRewardClaims/all',
    }),
})
export default class QuestInviteEntrysModal extends Vue {
    format = format;
    isLoading = true;
    limit = 5;
    page = 1;
    selectedItems: string[] = [];

    totals!: { [poolId: string]: number };
    referralRewardClaims!: TRewardClaimState;

    @Prop() id!: string;
    @Prop() pool!: TPool;
    @Prop() reward!: TQuestInvite;

    get total() {
        return this.totals[this.pool._id];
    }

    get rewardClaimsByPage() {
        if (!this.referralRewardClaims[this.pool._id]) return [];
        return Object.values(this.referralRewardClaims[this.pool._id])
            .filter((claim: TQuestInviteEntryAccount) => claim.page === this.page)
            .sort((a: TQuestInviteEntryAccount, b: TQuestInviteEntryAccount) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((c: TQuestInviteEntryAccount) => ({
                id: c._id,
                firstName: c.firstName,
                lastName: c.lastName,
                email: c.email,
                metadata: c.metadata,
                createdAt: c.createdAt,
                isApproved: c.isApproved,
            }))
            .slice(0, this.limit);
    }

    onShow() {
        this.listRewardClaims();
    }

    listRewardClaims() {
        this.isLoading = true;
        this.$store
            .dispatch('referralRewardClaims/list', {
                page: this.page,
                limit: this.limit,
                pool: this.pool,
                reward: this.reward,
            })
            .then(() => {
                this.isLoading = false;
            });
    }

    onChangeLimit(limit: number) {
        this.limit = limit;
        this.listRewardClaims();
    }

    onChecked(checked: boolean) {
        this.selectedItems = checked
            ? (this.rewardClaimsByPage.filter((c) => !c.isApproved).map((c) => c.id) as string[])
            : [];
    }

    onChangePage(page: number) {
        this.page = page;
        this.listRewardClaims();
    }

    async onClickAction(action: { variant: number; label: string }) {
        switch (action.variant) {
            case 0:
                const claims = this.selectedItems.map((id) => {
                    return this.referralRewardClaims[this.pool._id][id];
                });
                this.onClickApprove(claims);
                break;
        }
    }

    onClickApprove(claims: TQuestInviteEntryAccount[]) {
        this.$store.dispatch('referralRewardClaims/approveMany', {
            pool: this.pool,
            reward: this.reward,
            claims,
            page: this.page,
        });
        this.selectedItems = this.selectedItems.filter((id) => {
            return !claims.map((c) => c._id).includes(id);
        });
    }
}
