
import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';
import BaseCode from '@thxnetwork/dashboard/components/BaseCode.vue';
import BaseParticipantAccount from '@thxnetwork/dashboard/components/BaseParticipantAccount.vue';

const exampleCode = `const identity = await thx.identity.create();
// 36d33a59-5398-463a-ac98-0f7d9b201648

const identity = await thx.identity.get("a unique string");
// Will always return 36d33a59-5398-463a-ac98-0f7d9b201648
`;

@Component({
    components: { BaseCode, BaseParticipantAccount },
    computed: mapGetters({
        identitiesList: 'developer/identities',
    }),
})
export default class IdentitiesView extends Vue {
    format = format;
    pools!: IPools;
    isCopied = false;
    code = exampleCode;
    identitiesList!: TIdentityState;
    isLoadingList = false;
    isLoadingCreate = false;
    isLoadingRemove = false;
    page = 1;
    limit = 10;

    get identities() {
        return this.identitiesList.results.map((identity) => ({
            uuid: identity.uuid,
            participant: identity.account,
            createdAt: identity.createdAt,
            identity,
        }));
    }

    mounted() {
        this.listIdentities();
    }

    async listIdentities() {
        this.isLoadingList = true;
        await this.$store.dispatch('developer/listIdentities', { limit: this.limit, page: this.page });
        this.isLoadingList = false;
    }

    async onClickCreate() {
        this.isLoadingCreate = true;
        await this.$store.dispatch('developer/createIdentity');
        this.listIdentities();
        this.isLoadingCreate = false;
    }

    onClickDelete(identity: TIdentity) {
        this.$store.dispatch('developer/removeIdentity', identity);
    }

    onChangePage(page: number) {
        this.page = page;
        this.listIdentities();
    }
}
