
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseModal from './BaseModal.vue';
import hljs from 'highlight.js/lib/core';
import JavaScript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/atom-one-dark.css';
import { format } from 'date-fns';
import { WebhookRequestState } from '@thxnetwork/common/enums';

hljs.registerLanguage('javascript', JavaScript);

@Component({
    components: {
        BaseModal,
    },
})
export default class ModalWebhookRequests extends Vue {
    format = format;
    WebhookRequestState = WebhookRequestState;
    error = '';
    path = '';
    get example() {
        const path = new URL(this.webhook.url).pathname;
        return `
// Helper method to verify payload signature
function constructEvent(payload, signature, secret) {
	const hmac = crypto.createHmac('sha256', secret);
	hmac.update(payload);
    const calculatedSignature = hmac.digest('base64');
	if (signature !== calculatedSignature) throw new Error('Failed signature verification')
    return JSON.parse(payload);
}

// Sample endpoint controller (Express)
app.post('${path}', (req, res) => {
    let event;

    try {
        // Veries and parses the payload using the WEBHOOK_SIGNING_SECRET which you can get in Developer -> Webhooks
        event = constructEvent(req.body.payload, req.body.signature, WEBHOOK_SIGNING_SECRET);
    } catch (error) {
        return res.status(400).send("Webhook Error: " + err.message);
    }

    switch(event.type) {
        case 'reward_custom.paid': {
            // Handle the event
            // ...
            break
        }
        default : {
            console.log("Unhandled event type " + event.type)
        }
    }

    res.send();
});`;
    }

    @Prop() id!: string;
    @Prop() webhook!: TWebhook;
    @Prop() webhookRequests!: TWebhookRequest[];

    get exampleController() {
        return hljs.highlight(this.example, { language: 'javascript' }).value;
    }

    get webhookRequestList() {
        return this.webhookRequests.map((request) => {
            request.payloadFormatted = hljs.highlight(request.payload, { language: 'javascript' });
            request.responseFormatted =
                request.response && hljs.highlight(request.response, { language: 'javascript' });
            return request;
        });
    }

    onSubmit() {
        //
    }
}
