
import { ChainId } from '@thxnetwork/common/enums';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { PUBLIC_URL } from '@thxnetwork/dashboard/config/secrets';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: mapGetters({
        profile: 'account/profile',
    }),
})
export default class BaseDropdownNetwork extends Vue {
    @Prop() chainId!: ChainId;
    @Prop() disabled!: boolean;

    ChainId = ChainId;
    publicUrl = PUBLIC_URL;
    chainInfo = chainInfo;
    profile!: TAccount;
    currentChainId = ChainId.Polygon;

    created() {
        if (this.chainId) this.currentChainId = this.chainId;
        this.$emit('selected', this.currentChainId);
    }

    onSelectNetwork(chainId: ChainId) {
        this.currentChainId = chainId;
        this.$emit('selected', chainId);
    }
}
