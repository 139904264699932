
import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios';
import BaseIdenticon from '../BaseIdenticon.vue';
import { ChainId } from '@thxnetwork/common/enums';
import type { TERC20 } from '@thxnetwork/dashboard/types/erc20';

const QUICKSWAP_TOKEN_LIST =
    'https://unpkg.com/quickswap-default-token-list@1.2.41/build/quickswap-default.tokenlist.json';

@Component({
    components: {
        BaseIdenticon,
    },
})
export default class DropDownSelectPolygonERC20 extends Vue {
    tokenList: TERC20[] = [];

    @Prop() erc20!: TERC20;
    @Prop() chainId!: ChainId;
    @Prop() tokenAddress!: string;

    async mounted() {
        const { tokens } = await this.getLatestTokenList();
        this.tokenList = tokens;
    }

    async getLatestTokenList() {
        const r = await axios({
            method: 'GET',
            url: QUICKSWAP_TOKEN_LIST,
            withCredentials: false,
        });

        return r.data;
    }
}
