
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseDropdownSelectMultiple extends Vue {
    @Prop() disabled!: boolean;
    @Prop() options!: {
        img: string;
        label: string;
        value: any;
        disabled: boolean;
        selected: boolean;
        icon?: { variant: string; class: string };
    }[];

    get selected() {
        return this.options.filter((option) => option.selected);
    }
}
