
import Color from 'color';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { IWidgets } from '@thxnetwork/dashboard/store/modules/widgets';
import { DEFAULT_ELEMENTS } from '@thxnetwork/common/constants';
import { isValidUrl } from '@thxnetwork/dashboard/utils/url';
import BaseWidgetAlertPreview from '@thxnetwork/dashboard/components/widget/BaseWidgetAlertPreview.vue';
import BaseCodeExample from '@thxnetwork/dashboard/components/BaseCodeExample.vue';

@Component({
    components: {
        BaseWidgetAlertPreview,
        BaseCodeExample,
    },
    computed: mapGetters({
        pools: 'pools/all',
        widgets: 'widgets/all',
    }),
})
export default class WidgetsView extends Vue {
    pools!: IPools;
    widgets!: IWidgets;

    Color = Color;
    message = '';
    align = 'left';
    iconImg = '';
    cssSelector = '';
    isPublished = true;
    domain = '';
    error = '';

    isLoading = false;
    isSubmitting = false;

    get pool() {
        return this.pools[this.$route.params.id];
    }

    get widget() {
        if (!this.widgets[this.$route.params.id]) return;
        return Object.values(this.widgets[this.$route.params.id])[0];
    }

    get elements() {
        if (!this.widget) return DEFAULT_ELEMENTS;
        const { elements } = JSON.parse(this.widget.theme);
        return elements;
    }

    get isValidDomain() {
        return this.domain ? isValidUrl(this.domain) : null;
    }

    mounted() {
        this.$store.dispatch('widgets/list', this.pool).then(async () => {
            if (!this.widget) return;

            this.align = this.widget.align;
            this.message = this.widget.message;
            this.iconImg = this.widget.iconImg;
            this.cssSelector = this.widget.cssSelector;
            this.isPublished = this.widget.isPublished;
            this.domain = this.widget.domain;
        });
    }

    async onChangeDomain(url: string) {
        if (!isValidUrl(url)) return;
        this.isLoading = true;
        const domain = new URL(url);
        await this.$store.dispatch('widgets/update', { ...this.widget, domain: domain.origin });
        this.domain = url;
        this.isLoading = false;
    }

    onChangeCSSSelector(value: string) {
        this.cssSelector = value;
        this.onClickUpdate();
    }

    onChangePublished(value: boolean) {
        this.isPublished = value;
        this.onClickUpdate();
    }

    onChangeMessage(value: string) {
        this.message = value;
        this.onClickUpdate();
    }

    onChangeAlign(value: string) {
        this.align = value;
        this.onClickUpdate();
    }

    async onUploadIcon(event: any) {
        this.isSubmitting = true;
        const iconImg = await this.$store.dispatch('images/upload', event.target.files[0]);
        this.iconImg = iconImg;
        this.onClickUpdate();
    }

    onClickRemoveIcon() {
        this.iconImg = '';
        this.onClickUpdate();
    }

    async onClickUpdate() {
        if (!this.widget) return;
        this.isSubmitting = true;
        await this.$store.dispatch('widgets/update', {
            uuid: this.widget.uuid,
            isPublished: this.isPublished,
            iconImg: this.iconImg,
            poolId: this.pool._id,
            message: this.message,
            align: this.align,
            cssSelector: this.cssSelector,
            domain: this.domain,
        });
        this.isSubmitting = false;
    }
}
