
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseCardLogin from '@thxnetwork/dashboard/components/cards/BaseCardLogin.vue';

@Component({
    components: {
        BaseCardLogin,
    },
    computed: {
        ...mapGetters({
            account: 'account/profile',
            pools: 'pools/all',
        }),
    },
})
export default class App extends Vue {
    account!: TAccount;
    pools!: IPools;
    isLoading = false;

    get pool() {
        const { poolId } = this.$route.query;
        if (!poolId) return;
        return this.pools[poolId as string];
    }

    async mounted() {
        this.isLoading = true;
        try {
            await this.$store.dispatch('account/waitForAccount');
            if (this.account) {
                const { poolId, uuid } = this.$route.query;
                await this.$store.dispatch('pools/updateCollaborator', { poolId, uuid });
                await this.$store.dispatch('pools/list');
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    async onClickContinue() {
        await this.$router.push({ name: 'campaign', params: { id: this.$route.query.poolId as string } });
    }
}
