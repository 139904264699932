
import { AccountPlanType } from '@thxnetwork/common/enums';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseCardHome from '@thxnetwork/dashboard/components/cards/BaseCardHome.vue';
import BaseCodeExample from '@thxnetwork/dashboard/components/BaseCodeExample.vue';
import BaseCardPool from '@thxnetwork/dashboard/components/cards/BaseCardPool.vue';
import BaseModalPoolCreate from '@thxnetwork/dashboard/components/modals/BaseModalPoolCreate.vue';
import { QuestVariant } from '@thxnetwork/common/enums';
import { contentQuests, contentRewards } from '@thxnetwork/common/constants';

@Component({
    components: {
        BaseCardHome,
        BaseCodeExample,
        BaseCardPool,
        BaseModalPoolCreate,
    },
    computed: mapGetters({
        account: 'account/profile',
        pools: 'pools/all',
    }),
})
export default class HomeView extends Vue {
    window = window;
    pools!: IPools;
    account!: TAccount;
    docsUrl = process.env.VUE_APP_DOCS_URL;
    AccountPlanType = AccountPlanType;
    QuestVariant = QuestVariant;
    contentQuests = contentQuests;
    contentRewards = contentRewards;

    get firstPool() {
        const pools = Object.values(this.pools);
        if (!pools.length) return;
        return pools[0];
    }

    mounted() {
        this.$store.dispatch('pools/list');
    }
}
