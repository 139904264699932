
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TransactionState } from '@thxnetwork/common/enums';
import { format } from 'date-fns';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import BaseModal from '@thxnetwork/dashboard/components/modals/BaseModal.vue';

const stateVariantMap = {
    [TransactionState.Queued]: 'light',
    [TransactionState.Confirmed]: 'light',
    [TransactionState.Sent]: 'light',
    [TransactionState.Mined]: 'light',
    [TransactionState.Failed]: 'danger',
};

@Component({
    components: {
        BaseModal,
    },
})
export default class BaseModalWalletTransactions extends Vue {
    isLoading = false;
    error = '';
    TransactionState = TransactionState;
    format = format;

    @Prop() id!: string;
    @Prop() pool!: TPool;
    @Prop() wallet!: TWallet;

    get transactions() {
        return this.wallet.transactions.map((tx) => ({
            created: {
                created: format(new Date(tx.createdAt), 'dd-MM-yyyy HH:mm:ss'),
                updated: format(new Date(tx.updatedAt), 'dd-MM-yyyy HH:mm:ss'),
            },
            safeTxHash: {
                label: tx.safeTxHash ? tx.safeTxHash.substring(0, 10) : 'Pending',
                url:
                    tx.safeTxHash &&
                    `${chainInfo[this.wallet.chainId].safeURL}/transactions/history?safe=${this.wallet.address}`,
            },
            transactionHash: {
                label: tx.transactionHash ? tx.transactionHash.substring(0, 10) : 'Pending',
                url: tx.transactionHash && `${chainInfo[this.wallet.chainId].blockExplorer}/tx/${tx.transactionHash}`,
            },
            state: {
                label: TransactionState[tx.state],
                variant: stateVariantMap[tx.state],
            },
            tx,
        }));
    }

    onShow() {
        this.getWallet();
    }

    async getWallet() {
        await this.$store.dispatch('pools/getWallet', { pool: this.pool, wallet: this.wallet });
    }

    async onClickRefresh() {
        try {
            this.isLoading = true;
            await this.getWallet();
        } catch (error) {
            throw error;
        } finally {
            this.isLoading = false;
        }
    }

    async onClickRetry(tx: TTransaction) {
        try {
            this.isLoading = true;
            await this.$store.dispatch('pools/updateTransaction', {
                pool: this.pool,
                wallet: this.wallet,
                tx: { ...tx, state: TransactionState.Confirmed },
            });
        } catch (error) {
            throw error;
        } finally {
            this.isLoading = false;
        }
    }

    async onClickDelete(tx: TTransaction) {
        try {
            this.isLoading = true;
            await this.$store.dispatch('pools/removeTransaction', { pool: this.pool, wallet: this.wallet, tx });
        } catch (error) {
            throw error;
        } finally {
            this.isLoading = false;
        }
    }
}
