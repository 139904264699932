
import { ChainId } from '@thxnetwork/common/enums';
import { ChainInfo } from '@thxnetwork/dashboard/types/ChainInfo';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseBadgeNetwork extends Vue {
    chainInfo: ChainInfo | null = null;

    @Prop() chainId!: ChainId;
    @Prop() version!: string;

    mounted() {
        this.chainInfo = chainInfo[this.chainId];
    }
}
