
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseParticipantAccount from '@thxnetwork/dashboard/components/BaseParticipantAccount.vue';

@Component({
    components: {
        BaseParticipantAccount,
    },
})
export default class BaseCardLeaderboard extends Vue {
    limit = 10;
    isLoadingLeaderboard = false;
    leaderboard: { score: number; questEntryCount: number; rank: number; account: TAccount }[] = [];

    @Prop() pool!: TPool;
    @Prop() startDate!: Date;
    @Prop() endDate!: Date;

    mounted() {
        this.getLeaderboard();
    }

    @Watch('startDate')
    onChangeStartDate() {
        this.getLeaderboard();
    }

    @Watch('endDate')
    onChangeEndDate() {
        this.getLeaderboard();
    }

    async getLeaderboard() {
        this.isLoadingLeaderboard = true;
        this.leaderboard = await this.$store.dispatch('pools/getLeaderboard', {
            pool: this.pool,
            startDate: this.startDate,
            endDate: this.endDate,
            limit: this.limit,
        });
        this.isLoadingLeaderboard = false;
    }
}
