
import BaseModal from '@thxnetwork/dashboard/components/modals/BaseModal.vue';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    components: {
        BaseModal,
    },
    computed: mapGetters({
        pools: 'pools/all',
        profile: 'account/profile',
    }),
})
export default class DeveloperView extends Vue {
    childRoutes = [
        {
            name: 'API',
            class: 'fas fa-key',
            route: 'api',
        },
        {
            name: 'Identity',
            class: 'fas fa-id-badge',
            route: 'identities',
        },
        {
            name: 'Events',
            class: 'fas fa-bullhorn',
            route: 'events',
        },
        {
            name: 'Webhooks',
            class: 'fas fa-globe',
            route: 'webhooks',
        },
        {
            name: 'Wallets',
            class: 'fas fa-wallet',
            route: 'wallets',
        },
    ];
    mounted() {
        //
    }
}
