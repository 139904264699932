
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { AccountPlanType } from '@thxnetwork/common/enums';
import { toFiatPrice } from '@thxnetwork/dashboard/utils/price';
import { TInvoiceState } from '../store/modules/account';

@Component({
    computed: mapGetters({
        poolList: 'pools/all',
        invoiceList: 'account/invoices',
    }),
})
export default class WidgetsView extends Vue {
    AccountPlanType = AccountPlanType;
    toFiatPrice = toFiatPrice;
    isLoading = false;

    poolList!: IPools;
    invoiceList!: TInvoiceState;

    get invoices() {
        return this.invoiceList.map((invoice: TInvoice) => ({
            plan: invoice.plan,
            period: {
                start: new Date(invoice.periodStartDate).toLocaleDateString(),
                end: new Date(invoice.periodEndDate).toLocaleDateString(),
            },
            map: {
                count: invoice.mapCount,
                limit: invoice.mapLimit,
            },
            costPlan: invoice.costSubscription / 100,
            costAdditional: (invoice.additionalUnitCount * invoice.costPerUnit) / 100,
            costTotal: invoice.costTotal / 100,
        }));
    }

    async mounted() {
        await this.$store.dispatch('account/get');
        this.getInvoices();
    }

    async getInvoices() {
        this.isLoading = true;
        await this.$store.dispatch('account/listInvoices');
        this.isLoading = false;
    }
}
