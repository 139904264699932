
import BaseNothingHere from '@thxnetwork/dashboard/components/BaseListStateEmpty.vue';
import BaseBtnToggleArchive from '@thxnetwork/dashboard/components/buttons/BaseBtnToggleArchive.vue';
import BaseCardERC20 from '@thxnetwork/dashboard/components/cards/BaseCardERC20.vue';
import ModalERC20Create from '@thxnetwork/dashboard/components/modals/BaseModalERC20Create.vue';
import ModalERC20Import from '@thxnetwork/dashboard/components/modals/BaseModalERC20Import.vue';
import BaseModalPoolCreate from '@thxnetwork/dashboard/components/modals/BaseModalPoolCreate.vue';
import { IERC20s } from '@thxnetwork/dashboard/types/erc20';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    components: {
        BaseBtnToggleArchive,
        BaseCardERC20,
        ModalERC20Create,
        ModalERC20Import,
        BaseNothingHere,
        BaseModalPoolCreate,
    },
    computed: mapGetters({
        erc20List: 'erc20/all',
    }),
})
export default class CoinsView extends Vue {
    erc20List!: IERC20s;

    loadList() {
        this.$store.dispatch('erc20/list');
    }

    mounted() {
        this.$store.dispatch('account/get');
        this.loadList();
    }
}
