
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isValidUrl } from '@thxnetwork/dashboard/utils/url';
import BaseModal from './BaseModal.vue';
import BaseCardRewardExpiry from '../cards/BaseCardRewardExpiry.vue';
import BaseCardURLWebhook from '../cards/BaseCardURLWebhook.vue';
import BaseCardInfoLinks from '@thxnetwork/dashboard/components/cards/BaseCardInfoLinks.vue';

@Component({
    components: {
        BaseModal,
        BaseCardRewardExpiry,
        BaseCardURLWebhook,
        BaseCardInfoLinks,
    },
})
export default class ModalWebhookCreate extends Vue {
    isLoading = false;
    isVisible = true;
    error = '';
    url = '';

    @Prop() id!: string;
    @Prop({ required: false }) webhook!: TWebhook;

    get isValidWebhook() {
        if (!this.url) return;
        return isValidUrl(this.url);
    }

    get isDisabled() {
        return !this.isValidWebhook || this.isLoading;
    }

    onShow() {
        this.url = this.webhook ? this.webhook.url : '';
    }

    onSubmit() {
        this.isLoading = true;
        this.$store
            .dispatch(`developer/${this.webhook ? 'updateWebhook' : 'createWebhook'}`, {
                ...this.webhook,
                url: this.url,
            })
            .then(() => {
                this.$bvModal.hide(this.id);
                this.isLoading = false;
            });
    }
}
