
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { initWidget } from '@thxnetwork/dashboard/utils/widget';
import { API_URL } from '@thxnetwork/dashboard/config/secrets';
import axios from 'axios';

@Component({
    computed: mapGetters({
        brands: 'brands/all',
    }),
})
export default class WidgetPreviewView extends Vue {
    logoImgUrl = '';
    backgroundImgUrl = '';

    async mounted() {
        const { data } = await axios.get(API_URL + '/v1/widget/' + this.$route.params.poolId);
        this.logoImgUrl = data.logoUrl ? data.logoUrl : this.logoImgUrl;
        this.backgroundImgUrl = data.backgroundUrl ? data.backgroundUrl : this.backgroundImgUrl;

        initWidget(this.$route.params.poolId);
    }
}
