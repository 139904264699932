
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseCardDiscordNotification from '@thxnetwork/dashboard/components/BaseCardDiscordNotification.vue';

@Component({
    components: {
        BaseCardDiscordNotification,
    },
})
export default class BaseCollapseDiscordNotifications extends Vue {
    isCollapsed = true;

    @Prop() guild!: TDiscordGuild;

    update(guild: TDiscordGuild) {
        this.$emit('update', guild);
    }
}
