
import { Component, Prop, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';
import BaseModalDelete from '@thxnetwork/dashboard/components/modals/BaseModalDelete.vue';

@Component({
    components: {
        BaseModalDelete,
    },
})
export default class BaseCardPool extends Vue {
    format = format;

    @Prop() pool!: TPool;

    onClick() {
        this.$router.push({ name: 'campaign', params: { id: this.pool._id } });
        this.$emit('click', this.pool);
    }

    async onClickDuplicate() {
        try {
            await this.$store.dispatch('pools/duplicate', this.pool);
        } catch (error) {
            this.$bvToast.toast((error as Error).toString(), {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
            });
        }
    }

    onClickDelete() {
        this.$bvModal.show(`modalDelete-${this.pool._id}`);
    }

    async remove(_id: string) {
        this.$store.dispatch('pools/remove', { _id });
    }
}
