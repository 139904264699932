
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

export type TTableBulkAction = { variant: number; label: string };

@Component({})
export default class BaseCardTableHeader extends Vue {
    @Prop() totals!: { [poolId: string]: number };
    @Prop() selectedItems!: string[];
    @Prop() actions!: TTableBulkAction[];
    @Prop() page!: number;
    @Prop() limit!: number;
    @Prop() sorts!: { value: string; text: string }[];
    @Prop() pool!: TPool;
    @Prop() totalRows!: number;
    @Prop() query!: string;
    @Prop({ default: true }) hideQuery!: boolean;
    @Prop() toggleLabel!: string;
    @Prop() published!: boolean;

    pageModel = 1;
    selectedBulkAction: TTableBulkAction | null = null;

    get isPublishFilterShown() {
        return typeof this.published !== 'undefined';
    }

    @Watch('page')
    onChangePage(page: number) {
        this.pageModel = page;
    }

    mounted() {
        this.selectedBulkAction = this.actions[0];
    }

    onClickAction(item: TTableBulkAction) {
        this.selectedBulkAction = item;
        this.$emit('click-action', item);
    }
}
