
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QuestVariant } from '@thxnetwork/common/enums';
import BaseModalQuestCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestCreate.vue';
import BaseDropdownEventType from '@thxnetwork/dashboard/components/dropdowns/BaseDropdownEventType.vue';

@Component({
    components: {
        BaseModalQuestCreate,
        BaseDropdownEventType,
    },
})
export default class ModalQuestCustomCreate extends Vue {
    isLoading = false;
    error = '';
    amount = 0;
    limit = 0;
    eventName = '';
    isCopied = false;
    isVisible = true;

    @Prop() id!: string;
    @Prop() pool!: TPool;
    @Prop({ required: false }) quest!: TQuestCustom;

    get isSubmitDisabled() {
        return false;
    }

    onShow() {
        this.amount = this.quest && this.quest.amount ? this.quest.amount : this.amount;
        this.limit = this.quest && this.quest.limit ? this.quest.limit : this.limit;
        this.eventName = this.quest ? this.quest.eventName : this.eventName;
    }

    async onSubmit(payload: TBaseQuest) {
        this.isLoading = true;
        try {
            await this.$store.dispatch(`pools/${this.quest ? 'updateQuest' : 'createQuest'}`, {
                ...this.quest,
                ...payload,
                variant: QuestVariant.Custom,
                amount: this.amount,
                limit: this.limit,
                eventName: this.eventName,
            });
            this.$bvModal.hide(this.id);
            this.$emit('submit', { isPublished: payload.isPublished });
            this.isLoading = false;
        } catch (error: any) {
            this.error = error.message;
        } finally {
            this.isLoading = false;
        }
    }
}
