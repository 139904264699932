
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getPlatform, getUserUrl } from '../types/rewards';
import { providerIconMap } from '@thxnetwork/common/maps';
import { AccessTokenKind } from '@thxnetwork/common/enums';

export function parseConnectedAccounts(account: { tokens: TToken[] }) {
    if (!account.tokens.length) return [];
    return account.tokens
        .map((a: TToken & { user?: TTwitterUser }) => {
            return {
                platform: {
                    name: getPlatform(a.kind)?.name,
                    icon: providerIconMap[a.kind],
                },
                kind: a.kind,
                user: a.user,
                userId: a.userId,
                metadata: a.metadata,
                url: getUserUrl(a),
            };
        })
        .filter((a) => a);
}

@Component({})
export default class BaseParticipantConnectedAccount extends Vue {
    AccessTokenKind = AccessTokenKind;

    @Prop() id!: string;
    @Prop() account!: {
        kind: AccessTokenKind;
        url: string;
        platform: { icon: string };
        userName: string;
        userId: string;
        user: TTwitterUser;
        metadata: { username: string };
    };
}
