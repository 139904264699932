
import type { TPool } from '@thxnetwork/types/interfaces';
import type { TERC721 } from '@thxnetwork/dashboard/types/erc721';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseModal from './BaseModal.vue';

@Component({
    components: {
        BaseModal,
    },
    computed: mapGetters({}),
})
export default class ModalERC721MetadataCreateCSV extends Vue {
    @Prop() pool!: TPool;
    @Prop() erc721!: TERC721;

    async downloadCsv() {
        await this.$store.dispatch('erc721/createMetadataCSV', {
            pool: this.pool,
            erc721: this.erc721,
        });
        this.$bvModal.hide('modalNFTCreateMetadataCsv');
    }
}
