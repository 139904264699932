
import { ERC20Type } from '@thxnetwork/dashboard/types/erc20';
import { plans } from '@thxnetwork/dashboard/utils/plans';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseNavbarNav from './BaseNavbarNav.vue';
import BaseModalPoolCreate from '@thxnetwork/dashboard/components/modals/BaseModalPoolCreate.vue';
import { BASE_URL, WIDGET_URL } from '@thxnetwork/dashboard/config/secrets';

@Component({
    components: {
        BaseNavbarNav,
        BaseModalPoolCreate,
    },
    computed: mapGetters({
        pools: 'pools/all',
        account: 'account/profile',
    }),
})
export default class BaseNavbar extends Vue {
    dashboardUrl = BASE_URL;
    plans = plans;
    ERC20Type = ERC20Type;
    docsUrl = process.env.VUE_APP_DOCS_URL;
    walletUrl = process.env.VUE_APP_WALLET_URL;
    pools!: IPools;
    account!: TAccount;
    isVisible = true;

    get selectedPoolLogoImg() {
        if (!this.selectedPool) return;

        return this.selectedPool.brand && this.selectedPool.brand.logoImgUrl
            ? this.selectedPool.brand.logoImgUrl
            : `https://api.dicebear.com/7.x/identicon/png?seed=${this.selectedPool._id}`;
    }

    get firstPool() {
        const pools = Object.values(this.pools);
        if (!pools.length) return;
        return pools[0];
    }

    get selectedPool() {
        if (this.$route.params.id) {
            return this.pools[this.$route.params.id];
        } else {
            if (!this.firstPool) return;
            return this.firstPool;
        }
    }

    mounted() {
        this.$store.dispatch('pools/list', { archived: false });
    }

    onClickCampaignURL() {
        if (!this.selectedPool) return;
        window.open(WIDGET_URL + '/c/' + this.selectedPool.settings.slug, '_blank');
    }

    async onPoolSelect(pool: TPool) {
        await this.$store.dispatch('pools/read', pool._id);
        if (pool._id === this.$route.params.id) return;
        await this.$router.push({ name: 'campaign', params: { id: pool._id } });
    }
}
