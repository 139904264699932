
import { AccountPlanType } from '@thxnetwork/common/enums';
import { Prop, Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

export type RouteDefinition = {
    path: string;
    label: string;
    iconClasses: string;
    isNew?: boolean;
    isSoon?: boolean;
    isPremium?: boolean;
    isActive?: boolean;
    children: RouteDefinition[];
};

@Component({
    computed: mapGetters({
        account: 'account/profile',
    }),
})
export default class BaseNavbar extends Vue {
    AccountPlanType = AccountPlanType;
    account!: TAccount;

    @Prop() routes!: RouteDefinition[];
}
