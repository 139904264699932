
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { providerIconMap } from '@thxnetwork/common/maps';

@Component({
    computed: {
        ...mapGetters({
            account: 'account/profile',
        }),
    },
})
export default class App extends Vue {
    account!: TAccount;
    username = '';
    email = '';
    providerIconMap = providerIconMap;
    isLoading = false;

    @Prop() provider!: TProvider;

    get token() {
        return this.account.tokens.find((token) => token.kind === this.provider.kind);
    }

    get isDisabled() {
        if (!this.account || !this.token || this.isLoading) return true;
        return this.account.variant === this.token.kind;
    }

    mounted() {
        this.username = this.account.username || this.username;
        this.email = this.account.email || this.email;
    }

    async onClickConnect(provider: TProvider) {
        this.isLoading = true;
        try {
            await this.$store.dispatch('auth/connect', { kind: provider.kind, scopes: provider.scopes });
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }

    async onClickDisconnect(provider: TProvider) {
        this.isLoading = true;
        try {
            await this.$store.dispatch('auth/disconnect', provider.kind);
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }
}
