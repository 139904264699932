
import { ChainId } from '@thxnetwork/common/enums';
import { ERC721Variant, TERC721 } from '@thxnetwork/dashboard/types/erc721';
import { Component, Vue } from 'vue-property-decorator';
import BaseFormGroupNetwork from '../form-group/BaseFormGroupNetwork.vue';
import BaseModal from './BaseModal.vue';
import { NFTVariant } from '@thxnetwork/common/enums';

@Component({
    components: {
        BaseModal,
        BaseFormGroupNetwork,
    },
})
export default class ModalERC721Create extends Vue {
    NFTVariant = NFTVariant;
    ERC721Variant = ERC721Variant;
    loading = false;
    error = '';
    variant = NFTVariant.ERC721;
    tokenList: TERC721[] = [];
    chainId: ChainId = ChainId.PolygonMumbai;
    erc721Token: TERC721 | null = null;
    name = '';
    symbol = '';
    description = '';
    logoImg: File | null = null;

    async onClickSubmit() {
        this.loading = true;
        await this.create(this.variant);
        this.$bvModal.hide(`modalERC721Create`);
        this.loading = false;
    }

    async create(variant: NFTVariant) {
        switch (variant) {
            case NFTVariant.ERC721: {
                return await this.$store.dispatch('erc721/create', {
                    chainId: this.chainId,
                    name: this.name,
                    symbol: this.symbol,
                    description: this.description,
                    file: this.logoImg,
                });
            }
            case NFTVariant.ERC1155: {
                return await this.$store.dispatch('erc1155/create', {
                    chainId: this.chainId,
                    name: this.name,
                    description: this.description,
                    file: this.logoImg,
                });
            }
        }
    }
}
