
import { Component, Prop, Vue } from 'vue-property-decorator';
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/atom-one-dark.css';
import XML from 'highlight.js/lib/languages/xml';
import JavaScript from 'highlight.js/lib/languages/javascript';
import TypeScript from 'highlight.js/lib/languages/typescript';
import Shell from 'highlight.js/lib/languages/shell';

hljs.registerLanguage('shell', Shell);
hljs.registerLanguage('XML', XML);
hljs.registerLanguage('JavaScript', JavaScript);
hljs.registerLanguage('TypeScript', TypeScript);

@Component({})
export default class BaseCodeExample extends Vue {
    isCopied = false;
    currentIndex = 0;
    langMap = {
        JavaScript: 'js',
        TypeScript: 'ts',
        Shell: 'shell',
        XML: 'xml',
    };
    @Prop({ default: [] }) codes!: string[];
    @Prop() languages!: 'JavaScript' | 'TypeScript' | 'Shell' | 'XML';

    get codeExample() {
        if (!this.codes.length) return;
        const language = this.langMap[this.languages[this.currentIndex]];
        return hljs.highlight(this.codes[this.currentIndex], { language }).value;
    }
}
