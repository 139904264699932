
import hljs from 'highlight.js/lib/core';
import Shell from 'highlight.js/lib/languages/shell';
import { Component, Prop, Vue } from 'vue-property-decorator';

hljs.registerLanguage('shell', Shell);

@Component({})
export default class BaseCardURLWebhook extends Vue {
    isCopied = false;
    isVisible = true;

    @Prop() code!: string;
    @Prop({ default: true }) visible!: boolean;
    @Prop() title!: string;
    @Prop() description!: string;

    get codeExample() {
        return hljs.highlight(this.code || '', { language: 'shell' }).value;
    }
}
