
import BaseModalDelete from '@thxnetwork/dashboard/components/modals/BaseModalDelete.vue';
import BaseModalWalletCreate from '@thxnetwork/dashboard/components/modals/BaseModalWalletCreate.vue';
import BaseModalWalletTransactions from '@thxnetwork/dashboard/components/modals/BaseModalWalletTransactions.vue';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { format } from 'date-fns';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    components: {
        BaseModalDelete,
        BaseModalWalletCreate,
        BaseModalWalletTransactions,
    },
    computed: mapGetters({
        walletList: 'pools/wallets',
        poolList: 'pools/all',
    }),
})
export default class SettingsWallets extends Vue {
    error = '';
    isCopied = false;
    format = format;
    chainInfo = chainInfo;
    isLoading = false;
    walletList!: TWallet[];
    poolList!: IPools;

    get pool() {
        return this.poolList[this.$route.params.id];
    }

    get wallets() {
        if (!this.walletList) return [];
        return this.walletList.map((wallet: TWallet) => ({
            chainId: wallet.chainId,
            address: {
                address: wallet.address,
                url: `${chainInfo[wallet.chainId].safeURL}/transactions/history?safe=${wallet.address}`,
            },
            transactions: wallet.transactions.length,
            createdAt: wallet.createdAt,
            wallet,
        }));
    }

    mounted() {
        this.listWallets();
    }

    async listWallets() {
        await this.$store.dispatch('pools/listWallets', { pool: this.pool });
    }

    async onDelete(walletId) {
        this.isLoading = true;
        try {
            await this.$store.dispatch('pools/removeWallet', { pool: this.pool, walletId });
            await this.listWallets();
            this.$bvModal.hide(`modalDelete${walletId}`);
        } catch (error) {
            throw error;
        } finally {
            this.isLoading = false;
        }
    }
}
