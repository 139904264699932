
import { Component, Vue } from 'vue-property-decorator';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { isAddress } from 'web3-utils';
import BaseDropDownSelectPolygonERC20 from '../dropdowns/BaseDropDownSelectPolygonERC20.vue';
import BaseModal from './BaseModal.vue';
import { ChainId, NFTVariant } from '@thxnetwork/common/enums';
import BaseFormGroupNetwork from '../form-group/BaseFormGroupNetwork.vue';
import BaseDropdownSelectWallet from '../dropdowns/BaseDropdownSelectWallet.vue';
import BaseDropdownSelectNftVariant from '../dropdowns/BaseDropdownSelectNftVariant.vue';

@Component({
    components: {
        BaseModal,
        BaseFormGroupNetwork,
        BaseDropDownSelectPolygonERC20,
        BaseDropdownSelectWallet,
        BaseDropdownSelectNftVariant,
    },
})
export default class ModalNftImport extends Vue {
    NFTVariant = NFTVariant;
    variant: NFTVariant = NFTVariant.ERC1155;
    loading = false;
    chainInfo = chainInfo;
    nftAddress = '';
    nftLogoImgUrl = '';
    showPreview = false;
    tokens: {
        rawMetadata: { name: string };
        media: { thumbnail: string }[];
        balance: number;
        tokenId: number;
        tokenUri: { raw: string };
    }[] = [];
    previewLoading = false;
    wallet: TWallet | null = null;
    chainId: ChainId = ChainId.Polygon;

    get isValidAddress() {
        return isAddress(this.nftAddress);
    }

    onChangeVariant(variant: NFTVariant) {
        this.variant = variant;
        this.nftAddress = '';
    }

    async submit() {
        if (!this.wallet) return;
        this.loading = true;

        try {
            await this.$store.dispatch(`${this.variant}/import`, {
                chainId: this.chainId,
                address: this.wallet.address,
                contractAddress: this.nftAddress,
            });

            this.$bvModal.hide(`modalNftImport`);
            this.tokens = [];
        } catch (error) {
            throw new Error((error as any).message);
        } finally {
            this.loading = false;
        }
    }

    async getPreview(address: string) {
        if (!this.wallet || !isAddress(address)) {
            this.showPreview = false;
            this.tokens = [];
            return;
        }

        try {
            this.previewLoading = true;
            this.tokens = await this.$store.dispatch(`${this.variant}/preview`, {
                chainId: this.chainId,
                address: this.wallet.address,
                contractAddress: address,
            });
            this.previewLoading = false;
            this.showPreview = true;
        } catch (err) {
            this.previewLoading = false;
            this.showPreview = false;
        }
    }
}
