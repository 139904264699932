
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ERC20Type } from '@thxnetwork/dashboard/types/erc20';
import type { TERC20 } from '@thxnetwork/dashboard/types/erc20';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import poll from 'promise-poller';
import BaseCard from '@thxnetwork/dashboard/components/cards/BaseCard.vue';
import BaseBadgeNetwork from '@thxnetwork/dashboard/components/badges/BaseBadgeNetwork.vue';
import BaseIdenticon from '@thxnetwork/dashboard/components/BaseIdenticon.vue';
import BaseAnchorAddress from '../BaseAnchorAddress.vue';
import { mapGetters } from 'vuex';

@Component({
    components: {
        BaseCard,
        BaseBadgeNetwork,
        BaseIdenticon,
        BaseAnchorAddress,
    },
    computed: mapGetters({
        account: 'account/profile',
    }),
})
export default class BaseCardERC20 extends Vue {
    ERC20Type = ERC20Type;
    isLoading = true;
    isDeploying = false;
    error = '';
    account!: TAccount;

    @Prop() erc20!: TERC20;

    get isOwner() {
        if (!this.account) return;
        return this.erc20.sub === this.account.sub;
    }

    async mounted() {
        if (!this.erc20.address) {
            this.isDeploying = true;
            this.waitForAddress();
        } else {
            this.isDeploying = false;
            this.isLoading = false;
        }
    }

    openTokenUrl() {
        const url = `${chainInfo[this.erc20.chainId].blockExplorer}/token/${this.erc20.address}`;
        return (window as any).open(url, '_blank').focus();
    }

    waitForAddress() {
        const taskFn = async () => {
            const erc20 = await this.$store.dispatch('erc20/read', this.erc20);
            if (erc20 && erc20.address.length) {
                this.isDeploying = false;
                this.isLoading = false;
                return Promise.resolve(erc20);
            } else {
                this.isLoading = false;
                return Promise.reject(erc20);
            }
        };

        poll({ taskFn, interval: 3000, retries: 10 });
    }

    async onClickDelete() {
        this.isLoading = true;
        await this.$store.dispatch('erc20/remove', this.erc20);
        this.isLoading = false;
    }
}
