
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    components: {},
    computed: {
        ...mapGetters({
            account: 'account/profile',
            pools: 'pools/all',
        }),
    },
})
export default class ViewDashboardConfirm extends Vue {
    isReady!: boolean;

    onClickClose() {
        window.close();
    }
}
