
import { type TQuestSocialProvider, providerList } from '@thxnetwork/dashboard/types/rewards';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseDropdownQuestProvider extends Vue {
    providerList = providerList;

    @Prop({ default: () => providerList[0] }) provider!: TQuestSocialProvider;
}
