
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { IPools, TWalletState } from '../../store/modules/pools';
import BaseIdenticon from '../BaseIdenticon.vue';

@Component({
    components: {
        BaseIdenticon,
    },
    computed: mapGetters({
        poolList: 'pools/all',
        walletList: 'pools/wallets',
    }),
})
export default class BaseDropdownSelectWallet extends Vue {
    poolList!: IPools;
    walletList!: TWalletState;
    loading = false;

    @Prop() pool!: TPool;
    @Prop() wallet!: TWallet;

    async mounted() {
        if (!this.pool) {
            await Promise.all(
                Object.values(this.poolList).map((pool) => this.$store.dispatch('pools/listWallets', { pool })),
            );
        } else {
            this.$store.dispatch('pools/listWallets', { pool: this.pool });
        }
    }

    get wallets() {
        return this.pool ? this.walletList[this.pool._id] : Object.values(this.walletList).flat();
    }
}
