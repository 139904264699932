
import { IPools } from '@thxnetwork/dashboard/store/modules/pools';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { IWidgets } from '@thxnetwork/dashboard/store/modules/widgets';
import BaseWidgetAlertPreview from '@thxnetwork/dashboard/components/widget/BaseWidgetAlertPreview.vue';
import BaseCodeExample from '@thxnetwork/dashboard/components/BaseCodeExample.vue';
import Color from 'color';
import { WIDGET_URL, AUTH_URL, BASE_URL } from '@thxnetwork/dashboard/config/secrets';
import { DEFAULT_ELEMENTS, DEFAULT_COLORS } from '@thxnetwork/common/constants';
import { isValidUrl } from '@thxnetwork/dashboard/utils/url';

@Component({
    components: {
        BaseWidgetAlertPreview,
        BaseCodeExample,
    },
    computed: mapGetters({
        brands: 'brands/all',
        pools: 'pools/all',
        widgets: 'widgets/all',
    }),
})
export default class WidgetsView extends Vue {
    pools!: IPools;
    brands!: { [poolId: string]: TBrand };
    widgets!: IWidgets;
    widgetUrl = WIDGET_URL;
    authUrl = AUTH_URL;
    Color = Color;
    loading = false;
    isSubmitting = false;
    iconImg = ' ';
    backgroundImgUrl = '';
    logoImgUrl = '';
    elements = Object.assign({}, DEFAULT_ELEMENTS); // Clean object for reset behavior
    colors = Object.assign({}, DEFAULT_COLORS); // Clean object for reset behavior

    get pool() {
        return this.pools[this.$route.params.id];
    }

    get widget() {
        if (!this.widgets[this.$route.params.id]) return;
        return Object.values(this.widgets[this.$route.params.id])[0];
    }

    get brand() {
        return this.brands[this.$route.params.id];
    }

    get isBrandUpdateInvalid() {
        const backgroundUrlIsValid = this.backgroundImgUrl
            ? isValidUrl(this.backgroundImgUrl)
            : this.backgroundImgUrl === '';
        const logoUrlIsValid = this.logoImgUrl ? isValidUrl(this.logoImgUrl) : this.logoImgUrl === '';
        return logoUrlIsValid && backgroundUrlIsValid;
    }

    mounted() {
        this.$store.dispatch('widgets/list', this.pool).then(async () => {
            if (!this.widget) return;

            this.iconImg = this.widget.iconImg;

            const { elements, colors } = JSON.parse(this.widget.theme);
            for (const key in this.elements) {
                this.elements[key] = elements[key] ? elements[key] : this.elements[key];
            }
            for (const key in this.colors) {
                this.colors[key] = colors[key] ? colors[key] : this.colors[key];
            }
        });

        this.$store.dispatch('brands/getForPool', this.pool._id).then(async () => {
            if (!this.brand) return;
            this.backgroundImgUrl = this.brand.backgroundImgUrl;
            this.logoImgUrl = this.brand.logoImgUrl;
        });
    }

    async upload(file: File) {
        return await this.$store.dispatch('images/upload', file);
    }

    async onUpload(event: any, key: string) {
        const publicUrl = await this.upload(event.target.files[0]);
        Vue.set(this, key, publicUrl);
        await this.updateBrand();
    }

    async onClickRemoveBackground() {
        this.backgroundImgUrl = '';
        await this.updateBrand();
    }

    async onClickRemoveLogo() {
        this.logoImgUrl = '';
        await this.updateBrand();
    }

    async updateBrand() {
        this.loading = true;
        await this.$store.dispatch('brands/update', {
            pool: this.pool,
            brand: { backgroundImgUrl: this.backgroundImgUrl, logoImgUrl: this.logoImgUrl },
        });
        this.loading = false;
    }

    onClickResetColors() {
        for (const key in DEFAULT_COLORS) {
            Object.assign(this.colors[key], DEFAULT_COLORS[key]);
        }
    }

    onClickResetElements() {
        for (const key in DEFAULT_ELEMENTS) {
            Object.assign(this.elements[key], DEFAULT_ELEMENTS[key]);
        }
    }

    onClickPreview() {
        window.open(`${BASE_URL}/preview/${this.pool._id}`, '_blank');
    }

    async onClickUpdate() {
        if (!this.widget) return;
        this.isSubmitting = true;
        await this.$store.dispatch('widgets/update', {
            uuid: this.widget.uuid,
            poolId: this.pool._id,
            theme: JSON.stringify({ elements: this.elements, colors: this.colors }),
        });
        this.isSubmitting = false;
    }
}
