
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: mapGetters({
        profile: 'account/profile',
        pools: 'pools/all',
    }),
})
export default class BaseCardReward extends Vue {
    hover = false;

    @Prop() loading!: boolean;
    @Prop() url!: string;
}
