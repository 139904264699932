
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseCardRewardLimits extends Vue {
    isVisible = false;

    @Prop() limitSupply!: number;
    @Prop() limit!: number;

    mounted() {
        this.isVisible = this.limit > 0 || this.limitSupply > 0;
    }
}
