
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import BaseCode from '@thxnetwork/dashboard/components/BaseCode.vue';
import BaseParticipantAccount from '@thxnetwork/dashboard/components/BaseParticipantAccount.vue';

const exampleCode = `await thx.events.create({
    event: 'sign_up',
    identity: '36d33a59-5398-463a-ac98-0f7d9b201648',
});
`;

@Component({
    components: {
        BaseCode,
        BaseParticipantAccount,
    },
    computed: mapGetters({
        eventList: 'developer/events',
    }),
})
export default class DeveloperEventsView extends Vue {
    eventList!: TEventState;
    pools!: IPools;
    code = exampleCode;
    page = 1;
    limit = 10;
    format = format;

    get events() {
        return this.eventList.results.map((event: TEvent) => ({
            name: event.name,
            participant: event.account,
            createdAt: event.createdAt,
        }));
    }

    mounted() {
        this.listEvents();
    }

    async listEvents() {
        await this.$store.dispatch('developer/listEvents', { page: this.page, limit: this.limit });
    }

    onChangePage(page: number) {
        this.page = page;
        this.listEvents();
    }
}
