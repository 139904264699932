
import type { TPool } from '@thxnetwork/types/interfaces';
import type { TERC721 } from '@thxnetwork/dashboard/types/erc721';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseModal from './BaseModal.vue';

@Component({
    components: {
        BaseModal,
    },
    computed: mapGetters({}),
})
export default class BaseModalErc721MetadataUploadCSV extends Vue {
    @Prop() pool!: TPool;
    @Prop() erc721!: TERC721;

    selectedFile: File | null = null;
    isLoading = false;

    async downloadCsv() {
        this.isLoading = true;
        await this.$store.dispatch('erc721/createMetadataCSV', {
            pool: this.pool,
            erc721: this.erc721,
        });
        this.isLoading = false;
    }

    async uploadCsv() {
        try {
            this.isLoading = true;
            await this.$store.dispatch('erc721/uploadMetadataCSV', {
                pool: this.pool,
                erc721: this.erc721,
                file: this.selectedFile,
            });

            this.$emit('update');
        } catch (err) {
            throw err;
        } finally {
            this.isLoading = false;
            this.$bvModal.hide('modalNFTUploadMetadataCsv');
            this.selectedFile = null;
        }
    }
}
