
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AccessTokenKind } from '@thxnetwork/common/enums';
import BaseAvatar from '@thxnetwork/dashboard/components/BaseAvatar.vue';

@Component({
    components: {
        BaseAvatar,
    },
})
export default class BaseParticipantAccount extends Vue {
    @Prop() plain!: { type: boolean; default: false };
    @Prop() account!: { id: string; variant: AccessTokenKind; username: string; profileImg: string; email: string };
}
