
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseCardRewardExpiry extends Vue {
    isVisible = false;
    expirationDate: Date | string = '';
    expirationTime = '00:00:00';

    @Prop() expiryDate!: Date;

    mounted() {
        if (this.expiryDate) {
            const date = new Date(this.expiryDate);
            this.expirationDate = date;
            this.expirationTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            this.isVisible = true;
        }
    }

    get minDate() {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return date;
    }

    onChangeDate(date: Date) {
        this.expirationDate = date;
        this.change();
    }

    onChangeTime(time: string) {
        if (!this.expirationDate) return;
        this.expirationTime = time;
        this.change();
    }

    onClickExpiryRemove() {
        this.expirationDate = '';
        this.expirationTime = '00:00:00';
        this.$emit('change-date', '');
    }

    change() {
        if (!this.expirationDate) return;
        const expiryDate = new Date(this.expirationDate);
        const parts = this.expirationTime.split(':');
        expiryDate.setHours(Number(parts[0]));
        expiryDate.setMinutes(Number(parts[1]));
        expiryDate.setSeconds(Number(parts[2]));
        this.$emit('change-date', expiryDate.toISOString());
    }
}
