
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { TRewardPaymentState } from '@thxnetwork/dashboard/store/modules/pools';
import { getAddressURL } from '../../utils/chains';
import BaseCardTableHeader from '@thxnetwork/dashboard/components/cards/BaseCardTableHeader.vue';
import BaseModal from './BaseModal.vue';
import BaseParticipantAccount from '@thxnetwork/dashboard/components/BaseParticipantAccount.vue';

@Component({
    components: {
        BaseModal,
        BaseCardTableHeader,
        BaseParticipantAccount,
    },
    computed: mapGetters({
        paymentsList: 'pools/rewardPayments',
    }),
})
export default class BaseModalParticipants extends Vue {
    getAddressURL = getAddressURL;
    format = format;
    isLoading = false;
    selectedItems: string[] = [];
    paymentsList!: TRewardPaymentState;
    limit = 25;
    page = 1;
    query = '';

    @Prop() id!: string;
    @Prop() reward!: TReward;

    get rewardPayments(): {
        total: number;
        results: TRewardPayment[];
        meta?: { reachTotal?: number; participantCount?: number };
    } {
        if (!this.paymentsList[this.reward.poolId]) return { total: 0, results: [] };
        if (!this.paymentsList[this.reward.poolId][this.reward._id]) return { total: 0, results: [] };

        return this.paymentsList[this.reward.poolId][this.reward._id];
    }

    get payments() {
        return this.rewardPayments.results
            .sort((a: TRewardPayment, b: TRewardPayment) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((entry: any) => ({
                account: entry.account,
                pointBalance: entry.pointBalance,
                amount: entry.amount,
                entry,
            }));
    }

    mounted() {
        //
    }

    onChangeQuery() {
        this.page = 1;
        this.getPayments();
    }

    onInputQuery(query: string) {
        this.query = query;
    }

    async getPayments() {
        this.isLoading = true;
        await this.$store.dispatch('pools/listRewardPayments', {
            reward: this.reward,
            page: this.page,
            limit: this.limit,
            query: this.query,
        });
        this.isLoading = false;
    }

    onChangePage(page: number) {
        this.page = page;
        this.getPayments();
    }

    onChangeLimit(limit: number) {
        this.page = 1;
        this.limit = limit;
        this.getPayments();
    }
}
