
import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import BaseModalClientCreate from '@thxnetwork/dashboard/components/modals/BaseModalClientCreate.vue';
import BaseCode from '@thxnetwork/dashboard/components/BaseCode.vue';
import { format } from 'date-fns';

const exampleCode = `import { THXAPIClient } from '@thxnetwork/sdk';

const thx = new THXAPIClient({
    apiKey: 'q4ilZuGA4VPtrGhXug3i5taXrvDtidrzyv-gJN3yVo8T2stL6RwYQjqRoK-iUiAGGvhbG_F3TEFFuD_56Q065Q'
});
`;

@Component({
    components: {
        BaseModalClientCreate,
        BaseCode,
    },
    computed: mapGetters({
        pools: 'pools/all',
        clientList: 'developer/clients',
    }),
})
export default class Clients extends Vue {
    page = 1;
    limit = 5;
    isLoading = true;
    code = exampleCode;
    pools!: IPools;
    clientList!: TClientState;
    format = format;

    get clients() {
        if (!this.clientList) return [];
        return Object.values(this.clientList).map((client: TClient) => ({
            name: client.name,
            secret: client.secret,
            createdAt: client.createdAt,
            id: client._id,
        }));
    }

    mounted() {
        this.listClients();
    }

    onChangePage(page: number) {
        this.page = page;
        this.listClients();
    }

    onSubmit() {
        this.listClients();
    }

    async listClients() {
        this.isLoading = true;
        await this.$store.dispatch('developer/listClients');
        this.isLoading = false;
    }
}
