
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseCard from './BaseCard.vue';
import BaseBadgeNetwork from '../badges/BaseBadgeNetwork.vue';
import BaseIdenticon from '../BaseIdenticon.vue';
import BaseAnchorAddress from '../BaseAnchorAddress.vue';
import type { TERC721, TERC721Metadata } from '@thxnetwork/dashboard/types/erc721';
import { format } from 'date-fns';
import { API_URL } from '@thxnetwork/dashboard/config/secrets';
import BaseModalErc721MetadataMint from '@thxnetwork/dashboard/components/modals/BaseModalERC721MetadataMint.vue';
import type { TPool } from '@thxnetwork/dashboard/store/modules/pools';

@Component({
    components: {
        BaseAnchorAddress,
        BaseCard,
        BaseBadgeNetwork,
        BaseIdenticon,
        BaseModalErc721MetadataMint,
    },
})
export default class BaseListItemERC721Metadata extends Vue {
    apiUrl = API_URL;
    format = format;
    error = '';

    @Prop() metadata!: TERC721Metadata[];
    @Prop() erc721!: TERC721;
    @Prop() pool!: TPool;

    onEdit(metadata: TERC721Metadata) {
        this.$emit('edit', metadata);
    }

    onDelete(metadata: TERC721Metadata) {
        this.$emit('delete', metadata);
    }
}
