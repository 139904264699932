
import BaseCardInfoLinks from '@thxnetwork/dashboard/components/cards/BaseCardInfoLinks.vue';
import BaseCardQuestLocks from '@thxnetwork/dashboard/components/cards/BaseCardQuestLocks.vue';
import BaseModal from '@thxnetwork/dashboard/components/modals/BaseModal.vue';
import { isValidUrl } from '@thxnetwork/dashboard/utils/url';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        BaseModal,
        BaseCardInfoLinks,
        BaseCardQuestLocks,
    },
})
export default class ModalQuestCreate extends Vue {
    isPublished = false;
    isIPLimitEnabled = false;
    isReviewEnabled = false;
    title = '';
    description = '';
    infoLinks: TInfoLink[] = [{ label: '', url: '' }];
    file: File | null = null;
    locks: TQuestLock[] = [];
    imageFile: File | null = null;
    image = '';
    expiryDate: Date | string = '';

    expirationDate: Date | string = '';
    expirationTime = '00:00:00';

    @Prop() id!: string;
    @Prop() pool!: TPool;
    @Prop({ required: false }) quest!: TQuest;
    @Prop() label!: string;
    @Prop() loading!: boolean;
    @Prop() disabled!: boolean;
    @Prop() error!: string;

    mounted() {
        this.isPublished = this.quest ? this.quest.isPublished : this.isPublished;
        this.title = this.quest ? this.quest.title : this.title;
        this.description = this.quest ? this.quest.description : this.description;
        this.image = this.quest ? this.quest.image : this.image;
        // this.expiryDate = this.quest && this.quest.expiryDate ? this.quest.expiryDate : this.expiryDate;
        this.infoLinks = this.quest ? this.quest.infoLinks : this.infoLinks;
        this.locks = this.quest ? Object.values(this.quest.locks) : this.locks;
        this.isIPLimitEnabled = this.quest ? this.quest.isIPLimitEnabled : this.isIPLimitEnabled;
        this.isReviewEnabled = this.quest ? this.quest.isReviewEnabled : this.isReviewEnabled;
        if (this.quest && this.quest.expiryDate) {
            const date = new Date(this.quest.expiryDate);
            this.expirationDate = date;
            this.expirationTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            this.change();
        }
    }

    get isSubmitDisabled() {
        return this.disabled || !this.title || this.isLoading;
    }

    get isLoading() {
        return this.loading;
    }

    get minDate() {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return date;
    }

    onInputFile(file: File) {
        this.image = '';
        this.file = file;
    }

    onChangeDate(date: Date) {
        this.expirationDate = date;
        this.change();
    }

    onChangeTime(time: string) {
        this.expirationTime = time;
        this.change();
    }

    onChangeInfoLinks(infoLinks: TInfoLink[]) {
        this.infoLinks = [];
        this.infoLinks = infoLinks;
    }

    onClickExpiryRemove() {
        this.expirationDate = '';
        this.expirationTime = '00:00:00';
        this.expiryDate = '';
    }

    change() {
        if (!this.expirationDate) return;
        const expiryDate = new Date(this.expirationDate);
        const parts = this.expirationTime.split(':');
        expiryDate.setHours(Number(parts[0]));
        expiryDate.setMinutes(Number(parts[1]));
        expiryDate.setSeconds(Number(parts[2]));
        this.expiryDate = expiryDate.toISOString();
    }

    onSubmit() {
        this.$emit('submit', {
            _id: this.quest ? this.quest._id : undefined,
            poolId: this.pool._id,
            isPublished: this.isPublished,
            title: this.title,
            description: this.description,
            file: this.file,
            expiryDate: this.expiryDate,
            locks: JSON.stringify(this.locks),
            infoLinks: JSON.stringify(this.infoLinks.filter((link) => link.label && isValidUrl(link.url))),
            isIPLimitEnabled: this.isIPLimitEnabled,
            isReviewEnabled: this.isReviewEnabled,
        });
    }
}
