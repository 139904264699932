
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: mapGetters({
        profile: 'account/profile',
    }),
})
export default class SettingsView extends Vue {
    childRoutes = [
        {
            name: 'General',
            class: 'fas fa-cog',
            route: 'general',
        },
        {
            name: 'Team',
            class: 'fas fa-users',
            route: 'team',
        },
        {
            name: 'Appearance',
            class: 'fas fa-sliders-h',
            route: 'appearance',
        },
        {
            name: 'Widget',
            class: 'fas fa-share-alt',
            route: 'widget',
        },
    ];

    @Prop() pool!: TPool;
}
