
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseCard extends Vue {
    @Prop() isDeploying!: boolean;
    @Prop() isLoading!: boolean;
    @Prop() classes!: string;
    @Prop() bodyBgVariant!: string;
}
