
// The requirement for this component has not been implemented yet and is likely to replace the discord message requirement type
import { IPools } from '@thxnetwork/dashboard/store/modules/pools';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: mapGetters({
        pools: 'pools/all',
    }),
})
export default class BaseDropdownDiscordGuilds extends Vue {
    serverId = '';
    limit = 0;
    guild = null;

    @Prop({ required: false }) content!: string;
    @Prop({ required: false }) contentMetadata!: any;
    @Prop({ required: false }) amount!: number;

    pools!: IPools;

    get pool() {
        return this.pools[this.$route.params.id];
    }

    get guilds() {
        if (!this.pool) return [];
        return this.pool.guilds;
    }

    mounted() {
        this.serverId = this.content ? this.content : this.serverId;
        if (this.contentMetadata) {
            const { limit } = this.contentMetadata;
            this.limit = limit || this.limit;
        }
    }

    onChange(limit: number) {
        this.limit = limit;
        this.$emit('selected', {
            content: this.serverId,
            contentMetadata: {
                serverId: this.serverId,
                limit: this.limit,
            },
        });
    }
}
