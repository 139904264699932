
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseFormGroup extends Vue {
    @Prop() label!: string;
    @Prop() tooltip!: string;
    @Prop() description!: string;
    @Prop({ type: Boolean, default: false }) required!: boolean;
}
