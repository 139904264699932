
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ModalRewardCustomCreate extends Vue {
    @Prop() channelId!: string;
    @Prop() guild!: TDiscordGuild;

    get selectedDiscordChannel() {
        if (!this.guild.channels || !this.guild.channels.length) return;
        return this.guild.channels.find((channel) => channel.channelId === this.channelId);
    }

    onClick({ channelId }: { channelId: string }) {
        this.$emit('click', channelId);
    }
}
