
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseModal from '@thxnetwork/dashboard/components/modals/BaseModal.vue';

@Component({
    components: {
        BaseModal,
    },
})
export default class BaseModalParticipantBalanceReset extends Vue {
    isLoading = false;
    error = '';
    checkReset = '';

    @Prop() id!: string;
    @Prop() pool!: TPool;

    get isDisabled() {
        return this.checkReset !== 'reset';
    }

    async onClickParticipantsReset() {
        try {
            this.isLoading = true;
            await this.$store.dispatch('pools/resetParticipants', this.pool);
            this.$emit('hidden');
            this.$bvModal.hide(this.id);
        } catch (error) {
            this.error = (error as Error).toString();
        } finally {
            this.isLoading = false;
        }
    }
}
