
import { Component, Vue } from 'vue-property-decorator';
import BaseNavbarTop from '@thxnetwork/dashboard/components/BaseNavbarTop.vue';
import BaseModalOnboarding from '@thxnetwork/dashboard/components/modals/BaseModalOnboarding.vue';

@Component({
    components: {
        BaseNavbarTop,
        BaseModalOnboarding,
    },
})
export default class ViewDashboard extends Vue {}
