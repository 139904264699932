
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseFormGroupInputMultiple from '@thxnetwork/dashboard/components/form-group/BaseFormGroupInputMultiple.vue';

export const excludeOptions = [
    { value: '-is:retweet', text: 'Retweet' },
    { value: '-is:quote', text: 'Quote' },
    { value: '-is:reply', text: 'Reply' },
];

@Component({
    components: { BaseFormGroupInputMultiple },
})
export default class BaseCardTwitterQueryOperators extends Vue {
    @Prop() from!: string[];
    @Prop() to!: string[];
    @Prop() text!: string[];
    @Prop() url!: string[];
    @Prop() hashtags!: string[];
    @Prop() mentions!: string[];
    @Prop() media!: string;
    @Prop() excludes!: string[];

    excludeOptions = excludeOptions;

    get excludesModel() {
        return this.excludes;
    }

    set excludesModel(value: string[]) {
        this.$emit('excludes', value);
    }
}
