
import { GTM } from '@thxnetwork/dashboard/config/secrets';
import { initGTM } from '@thxnetwork/dashboard/utils/ga';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
    components: {},
    computed: {
        ...mapState('auth', {
            isReady: 'isReady',
        }),
    },
})
export default class App extends Vue {
    isReady!: boolean;

    created() {
        if (GTM) initGTM();

        const isDarkModeEnabled = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        this.$store.commit('account/setDarkMode', isDarkModeEnabled);
    }
}
