
import { ChainId } from '@thxnetwork/common/enums';
import { TERC20 } from '@thxnetwork/dashboard/types/erc20';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseDropDownSelectPolygonERC20 from '../dropdowns/BaseDropDownSelectPolygonERC20.vue';
import BaseModal from './BaseModal.vue';
import BaseFormGroupNetwork from '../form-group/BaseFormGroupNetwork.vue';
import { chainInfo } from '@thxnetwork/dashboard/utils/chains';
import { isAddress } from 'web3-utils';

@Component({
    components: {
        BaseModal,
        BaseFormGroupNetwork,
        BaseDropDownSelectPolygonERC20,
    },
    computed: mapGetters({}),
})
export default class ModalERC20Import extends Vue {
    loading = false;
    chainInfo = chainInfo;
    selectedChainId: ChainId | null = null;
    erc20: TERC20 | null = null;
    erc20Address = '';
    erc20LogoImgUrl = '';
    showPreview = false;
    name = '';
    symbol = '';
    totalSupplyInWei = '';
    previewLoading = false;

    @Prop() chainId!: ChainId;

    get isValidAddress() {
        return isAddress(this.erc20Address);
    }

    async submit() {
        this.loading = true;

        await this.$store.dispatch('erc20/import', {
            chainId: this.selectedChainId,
            address: this.erc20Address,
            logoImgUrl: this.erc20LogoImgUrl,
        });

        this.$bvModal.hide(`modalERC20Import`);
        this.loading = false;
        this.erc20 = null;
    }

    onERC20Selected(erc20: TERC20) {
        this.erc20 = erc20;
        this.erc20Address = erc20 ? erc20.address : '';
        this.erc20LogoImgUrl = erc20 && erc20.logoURI ? erc20.logoURI : '';
    }

    async onInputAddress(address: string) {
        if (!isAddress(address)) throw new Error('Invalid Contract Address');

        try {
            this.previewLoading = true;
            const { name, symbol, totalSupplyInWei } = await this.$store.dispatch('erc20/preview', {
                chainId: this.selectedChainId,
                address: address,
            });
            this.name = name;
            this.symbol = symbol;
            this.totalSupplyInWei = totalSupplyInWei;
            this.previewLoading = false;
            this.showPreview = true;
        } catch (err) {
            this.previewLoading = false;
            this.showPreview = false;
            throw new Error('Could not import for this address.');
        }
    }
}
