
import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import BaseModalWebhookCreate from '@thxnetwork/dashboard/components/modals/BaseModalWebhookCreate.vue';
import BaseModalWebhookRequests from '@thxnetwork/dashboard/components/modals/BaseModalWebhookRequests.vue';

@Component({
    components: { BaseModalWebhookCreate, BaseModalWebhookRequests },
    computed: mapGetters({
        webhookList: 'developer/webhooks',
        account: 'account/profile',
    }),
})
export default class CampaignConfigWebhooks extends Vue {
    pools!: IPools;
    webhookList!: TWebhookState;
    account!: TAccount;

    isCopied = false;

    get webhooks() {
        return Object.values(this.webhookList).map((w) => {
            return {
                url: w.url,
                webhookRequests: w.webhookRequests,
                signingSecret: w.signingSecret,
                status: w.status,
                _id: w._id,
            };
        });
    }

    async mounted() {
        await this.$store.dispatch('developer/listWebhooks');
    }

    onClickWebhookRequests() {
        //
    }

    async onClickDelete(item: { id: string }) {
        const webhook = Object.values(this.webhooks).find((webhook) => webhook._id === item.id);
        await this.$store.dispatch('webhooks/deleteWebhook', webhook);
    }
}
