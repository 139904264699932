
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { IERC20s } from '@thxnetwork/dashboard/types/erc20';
import { ChainId } from '@thxnetwork/common/enums';
import BaseIdenticon from '../BaseIdenticon.vue';

@Component({
    components: {
        BaseIdenticon,
    },
    computed: mapGetters({
        erc20List: 'erc20/all',
    }),
})
export default class ModalAssetPoolCreate extends Vue {
    ChainId = ChainId;
    erc20List!: IERC20s;

    @Prop() erc20!: TERC20;
    @Prop() chainId!: ChainId;

    get erc20s() {
        return Object.values(this.erc20List).filter((erc20) => erc20.chainId === this.chainId);
    }

    async mounted() {
        await this.$store.dispatch('erc20/list');
        this.$emit('update', this.erc20);
    }
}
