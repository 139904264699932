
import { mapGetters } from 'vuex';
import { QuestVariant } from '@thxnetwork/common/enums';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TQuestState } from '@thxnetwork/dashboard/store/modules/pools';
import BaseModalQuestCreate from '@thxnetwork/dashboard/components/modals/BaseModalQuestCreate.vue';

@Component({
    components: {
        BaseModalQuestCreate,
    },
    computed: mapGetters({
        questList: 'pools/quests',
    }),
})
export default class ModalQuestInviteCreate extends Vue {
    isLoading = false;
    error = '';
    amount = 0;
    amountInvitee = 0;
    requiredQuest: TQuest | null = null;

    questList!: TQuestState;

    @Prop() id!: string;
    @Prop() total!: number;
    @Prop() pool!: TPool;
    @Prop({ required: false }) quest!: TQuestInvite;

    get quests() {
        if (!this.questList[this.pool._id]) return [];
        return this.questList[this.pool._id].results;
    }

    get isSubmitDisabled() {
        return this.isLoading;
    }

    onShow() {
        this.amount = this.quest ? this.quest.amount : this.amount;
        this.amountInvitee = this.quest ? this.quest.amountInvitee : this.amountInvitee;
        this.requiredQuest =
            this.quest && this.quest.requiredQuest
                ? (this.quests.find((quest) => quest._id === this.quest.requiredQuest.questId) as TQuest)
                : this.requiredQuest;
    }

    async onSubmit(payload: TBaseQuest) {
        this.isLoading = true;
        try {
            await this.$store.dispatch(`pools/${this.quest ? 'updateQuest' : 'createQuest'}`, {
                ...this.quest,
                ...payload,
                variant: QuestVariant.Invite,
                amount: this.amount,
                amountInvitee: this.amountInvitee,
                requiredQuest: this.requiredQuest && {
                    questId: this.requiredQuest._id,
                    variant: this.requiredQuest.variant,
                },
            });
            this.$bvModal.hide(this.id);
            this.$emit('submit', { isPublished: payload.isPublished });
            this.isLoading = false;
        } catch (error: any) {
            this.error = error.message;
        } finally {
            this.isLoading = false;
        }
    }
}
